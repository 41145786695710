/* Grid Render Styles */
// variables
$color-title: #5b3c7d;
$color-body: #3F3F3F;
$color-titleInverse: #FFF;

.planGrid {
    // planGrid styles
    background: $row-bg; //#F6F6F6;
    color: $color-body;
    font-size: 22px;
    margin: 20px auto;
    padding: 60px 0;
    position: relative;
    text-align: center;

    @include add-verticalTriangle(116px, 30px, $light);

    .btn {
        display: inline-block;
    }

    &-title {
        color: #231033;
        font-size: 26px;
        font-weight: 300;
    }

    &-subTitle {
        color: #3F3F3F;
        font-size: 18px;
        font-weight: 300;
    }

    &-item {
        border: 1px solid #8e8e8e;
        font-size: 18px;
        margin: 32px 0;

        &Title {
            background: $color-title;
            color: $color-titleInverse;
            font-size: 16px;
            font-weight: 600;
            line-height: 1.5;
            padding: 16px 5px;
        }

        &SubTitle {
            font-size: 18px;
            font-weight: 300;
            line-height: 1.55;
        }

        .btn {
            font-size: 16px;
            font-weight: bold;
        }
    }

    &-iconLetter {
        $minSize: 109px;
        border: 7px solid $color-title;
        border-radius: 50%;
        color: $color-title;
        display: inline-block;
        font-size: 71px;
        font-weight: 600;
        height: $minSize;
        line-height: 1.36;
        margin: 0 30px 40px;
        text-align: center;
        width: $minSize;
    }

    &-topWrap {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        .box1 {
            order: 1;
            padding: 0;
        }

        .box2 {
            order: 2;
            padding: 60px 15px 25px;
        }

        .box3 {
            order: 3;
            padding: 0 15px 50px;
        }
    }

    &-benefits {
        background: #ebebeb;
        border-top: 1px solid #8d8d8d;
        font-size: 16px;
        font-weight: normal;
        padding: 20px 20px;

        &Title {
            font-weight: bold;
            text-align: left;
        }

        &List {
            display: inline-block;
            font-weight: 300;
            margin: 0 0 15px 0;
            padding: 0;
            text-align: left;

            & > li {
                display: block;
                padding: 10px 15px 10px 1em;
                text-indent: -0.7em;

                &:before {
                    content: '•';
                    padding-right: 0.3em;
                }
            }
        }

        .btn-default {
            background: none;
            border: 2px solid #3C99FC;
            color: #3C99FC;
        }
    }
}


@mixin grid-plan-item--min-sm() {
    .planGrid {
        @include update-verticalTriangle(100px, 25px); //(80px, 20px);
        &-title {
            font-size: 34px;
        }

        &-subTitle {
            font-size: 24px;
        }

        &-item {
            text-align: left;

            &Title {
                background: none;
                color: $color-title;
                font-size: 30px;
            }

            .btn {
                display: block;

                & > span::after {
                    content: ' \e900';
                    display: inline;
                    margin: 0;
                    position: static;
                    white-space: pre;
                }
            }
        }

        &-topWrap {
            display: block;

            .box1 {
                padding-top: 65px;
            }

            .box2 {
                float: left;
                padding: 50px 35px 50px 25px;
            }
        }

        &-benefits {
            $itemPad: 25px;
            clear: both;
            font-size: 0;
            padding-bottom: 35px;
            position: relative;

            &Title, &List {
                display: inline-block;
                vertical-align: top;
            }

            &Title {
                font-size: 18px;
                padding: 0 $itemPad 0 0;
                width: 20%;
            }

            &List {
                font-size: 16px;
                width: 80%;

                > li {
                    display: table-cell;
                    padding: 0 $itemPad;
                }
            }

            .btn-default {
                $pad: 5px;
                background: none;
                border: none;
                bottom: $pad;
                font-size: 16px;
                font-weight: normal;
                position: absolute;
                right: $pad;
                text-decoration: underline;

                &::after {
                    text-decoration: underline;
                }
            }
        }
    }
}


@mixin grid-plan-item--min-lg() {
    .planGrid {

        &-topWrap {
            padding: 0 60px;
        }

        &-benefits {
            $itemPad: 25px;
            padding: 20px 50px 35px;

            &List {
                width: 60%;
            }

            .btn-default {
                display: inline-block;
                padding: 0 0 0 $itemPad;
                position: static;
                width: 20%;
            }
        }
    }
}
