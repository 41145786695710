.svg-lib {
    display: none;
}

.stat {
    $size: 220px;
    height: $size;
    position: relative;
    text-align: center;
    width: $size;

    &-disc {
        fill: none;
        stroke: #eee;
        stroke-linecap: round;
        stroke-width: 1.5px;

        &Fg {
            stroke: #369;
            // leave unset, css overrides
            // stroke-dasharray: 59, 100;
            transform: scaleX(-1);
            transform-origin: 50%;
        }
    }

    &.animate {
        .stat-discFg {
            // animation-duration is overridden by js
            animation: statDiscDash 3s cubic-bezier(0.190, 1.000, 0.220, 1.000);
        }
    }

    &-text {
        align-content: center;
        align-items: center;
        bottom: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        left: 0;
        margin: 20px;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
    }

    &-value {
        font-size: 62px;
    }

    &-desc {
        font-size: 14px;
    }
}

@keyframes statDiscDash {
    0% {
        stroke-dasharray: 0 100;
    }
}
