.learn-more {
  margin: 90px 0 50px;
  a:hover,
  a:focus {
    text-decoration: none;
  }
  h2 {
    color: $text-primary;
    font-size: em(18);
    font-weight: 600;
    margin-left: -15px;
    margin-right: -15px;
    position: relative;
    text-align: center;
    &:before {
      border-bottom: 1px solid $learnmore-border;
      content: " ";
      float: right;
      left: 0;
      position: absolute;
      top: 10px;
      width: 30%;
    }    
    &:after {
      border-bottom: 1px solid $learnmore-border;
      content: " ";
      float: right;
      position: absolute;
      right: 0;
      top: 10px;
      width: 30%;
    }
  }
  .cta {
    margin-top: 34px;
    padding-left: 8%;
    padding-right: 8%;
    @include clearfix();
  }
  .img-col {
    float: left;
    text-align: right;
    width: 25%;
  }
  .text-col {
    float: right;
    padding-left: 25px;
    width: 75%;
  }
  img {
    @include border-radius(50%);
    height: auto;
    max-width: 75px;
    width: 100%;
  }
  h3 {
    font-size: em(18);
    font-weight: 400;
    line-height: 1.4em;
  }
  p {
    color: $text-primary;
    margin-bottom: 15px;
  }
  .btn {
    width: 108px;
    &.btn-sm > span {
        display: block;
        margin-top: 3px;
        &:after {
            content: none;
        }
    }
  }
}
@mixin learn-more--min-sm() {
  .learn-more {
    h2 {
      margin-left: 0;
      margin-right: 0;
      &:before,
      &:after {
        width: 40%;
      }
    }
    .cta {
      margin-bottom: 20px;
      padding-left: 15px;
      padding-right: 15px;
    }
    .img-col {
      text-align: center;
      width: 45%;
    }
    .text-col {
      padding-left: 15px;
      width: 55%;
    }    
    img {
      max-width: 130px;
    }    
    h3 {
      font-weight: 300;
      margin-bottom: 4px;
    }
    p {
      font-size: em(12);
      line-height: 1.4em;
      margin-bottom: 30px;
    }
  }
}
@mixin learn-more--min-md() {
  .learn-more {
    .text-col {
      padding-left: 28px;
    }
    h3 {
      margin-bottom: 8px;
    }
    p {
      margin-bottom: 15px;
    }
  }
}
@mixin learn-more--min-xg {
  .learn-more {
    img {
      margin-left: 30px;
    }
  }
}