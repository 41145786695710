.featured-content {
    padding: 45px 0;

    h2 {
        font-size: em(28);
        font-weight: 400;
        text-align: center;
    }

    .feature {
        @include clearfix();
        padding-top: 40px;
    }

    .feature-image {
        float: left;
        padding-left: 20px;
        text-align: right;
        width: 27.5%;
    }

    img {
        height: auto;
        max-width: 120px;
        width: 100%;
        @include border-radius(50%);
    }

    .feature-text {
        float: right;
        font-weight: 300;
        padding-left: 25px;
        width: 72.5%;
    }

    p {
        padding-right: 5%;
    }

    h3 {
        font-size: em(18);
        line-height: 1.2em;
    }

    .btn-sm {
        background: $li-purple;
        border-color: $li-purple;
        line-height: 2.1;
        padding-top: 0;
    }
}
@mixin featured-content--min-sm() {
  .featured-content {
    .feature {
      padding-top: 30px;
    }
    h2 {
      font-size: em(34);
      font-weight: 300;
    }
    h3 {
      font-size: em(22);
      font-weight: 300;
      margin-top: 10px;
    }
    .feature-image {
      padding-left: 0;
      width: 40%;
    }
    img {
      max-width: none;
    }
    .feature-text {
      padding-left: 20px;
      width: 60%;
    }
    p {
      font-size: em(12);
    }
  }
}
@mixin featured-content--min-md() {
  .featured-content {
    img {
      max-width: 150px;
    }
  }
}
@mixin featured-content--min-lg() {
  .featured-content {
    .feature-image {
      width: 30.75%;
    }
    .feature-text {
      width: 69.25%;
    }
    h3 {
      font-size: em(26);
      padding-right: 12%;
    }
    p {
      font-size: em(14);
    }    
    .btn {
      margin-top: 8px;
    }
  }
}