.textblurb-cta {
    color: $li-dkpurple;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.3;
    padding: 40px 0;
    text-align: center;

    .textblurb {
        padding: 0 11%;
    }

    h1 {
        color: $li-mdpurple;
        font-size: 2.917em;
        font-weight: 600;

        sup {
            .final-expense & {
                font-size: 40%;
                top: -1.5em;
            }
        }
    }

    h2 {
        color: $li-mdpurple;
        font-size: 0.917em;
        font-weight: 300;
        line-height: 1.4;
    }

    .cta {
        padding: 0 4%;

        &:before {
            border-bottom: 2px solid $learnmore-border;
            content: "";
            display: block;
            margin: 40px auto;
            width: 80%;
        }

        .final-expense & {
            &:before {
                border: 0;
            }

            .btn {
                padding: 10px 14px;
            }
        }
    }

    p {
        color: #3f3f3f;
    }

    .btn {
        font-size: 16px;
        font-weight: 600;
        margin-top: 10px;
        padding: 10px 20px;
    }

    /*
    *  Variants
    */
    &.textblurb-cta--type-2 {
        padding: 80px 0;
        h1 {
            font-size: 25px;
            font-weight: 300;
            line-height: 1.36;
            margin-bottom: 36px;
        }
        p {
            font-size: 18px;
            line-height: 1.556;
            padding: 0 2%;
        }
        h2 {
            font-size: 59px;
            font-weight: 400;
            line-height: 1.4;
            sup {
                font-size: 30%;
                top: -1.75em;
            }
        }
        .btn {
            font-weight: 400;
            margin-top: 26px;
        }
    }

    &.has--bg {
        background: $row-bg;
    }

    &.has--banner {
        padding: 40px 0 150px;

        .textblurb,
        .cta {
            padding: 0 10%;
        }

        h1 {
            font-size: 59px;
            font-weight: 900;
            line-height: 80px;
            margin-top: 30px;
        }

        h2 {
            font-size: 25px;
            line-height: 34px;
        }

        p {
            font-size: 18px;
            font-weight: 600;
            line-height: 28px;
        }

        .cta {
            &:before {
                margin-bottom: 30px;
                width: 100%;
            }

            p {
                font-size: 16px;
                line-height: 26px;
            }
        }
    }
}
@mixin textblurb-cta--min-sm() {
    .textblurb-cta {
        padding: 80px 0 60px;

        .textblurb {
            margin: 0 auto;
            max-width: 940px;
            padding: 0 5%;
        }

        h2 {
            font-size: 1.333em;
        }

        .cta {
            .final-expense & {
                .btn {
                    padding: 10px 30px;
                }
            }
        }

        &.has--banner {
            padding-top: 80px;

            .textblurb {
                padding: 0 8%;
            }

            h2 {
                font-size: 30px;
                line-height: 41px;
            }

            p {
                font-size: 20px;
                margin: 0 auto 15px;
                max-width: 600px;
            }

            .cta {
                &:before {
                    margin-top: 25px;
                    width: 70%;
                }
            }
        }
        /*
        *  Variants
        */
        &.textblurb-cta--type-2 {
            padding: 80px 0;

            h1 {
                font-size: 34px;
            }

            h2 {
                line-height: 1;
            }

            p {
                font-size: 20px;
            }

            .cta {
                &:before {
                    margin: 28px auto;
                    max-width: 517px;
                }

                p {
                    font-size: 16px;
                    margin-left: auto;
                    margin-right: auto;
                    max-width: 500px;
                }
            }

            .btn {
                margin-top: 36px;
            }
        }
    }
}

@mixin textblurb-cta--min-md() {
    .textblurb-cta {
        &.has--banner {
            padding-bottom: 280px;
            .textblurb {
                max-width: 940px;
                padding: 0 5%;
            }

            h2 {
                font-size: 34px;
                line-height: 46px;
            }
            .cta {
                &:before {
                    width: 50%;
                }
            }
        }
    }
}
