.calc {
    background-image: url("/assets/images/backgrounds/calculator-sm.jpg");
    background-position: center;
    background-size: cover;
    color: $light;
    overflow: hidden;
    position: relative;

    .color-overlay {
        background-color: $li-purple;
        height: 100%;
        left: 0;
        opacity: 0.85;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__head, &__foot {
        position: relative;
    }

    &__head {
        padding: 50px 0;
        text-align: center;

        h1, p {
            margin-bottom: 0;
        }

        h1 {
            color: inherit;
            font-size: 28px;
            font-weight: 300;
            text-align: center;
        }

        p {
            font-size: 14px;
            font-weight: 400;
            margin-top: 15px;
        }
        &.summary {
            p {
                display: none;
            }
        }
    }

    &__content {
        margin: 0 auto;
        max-width: 290px;

        &__slide {
            p {
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 0;

                em {
                    font-size: 11px;
                }
            }

            .item-row {
                margin-bottom: 30px;
            }

            .field-item:first-child {
                .list-item {
                    margin-top: 30px;
                }
            }
        }

        .lic-summary {
            font-size: 14px;
            position: relative;
            text-align: center;

            a {
                color: inherit;
                text-decoration: underline;
            }

            &-top {
                margin: 2em auto;
                text-align: center;
            }

            &-result {
                font-size: 34px;
                font-weight: 300;
            }

            &-low {
                font-size: 14px;
                font-weight: 400;
                margin: 2.2em 0 4em;
                text-align: left;

                .call {
                    font-size: 18px;
                    font-weight: 400;
                    margin-top: 40px;
                }
            }
        }
    }

    &__foot {
        padding: 30px 0 100px;
        text-align: center;
    }

    label {
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 10px;
    }

    input:not([type="checkbox"]):not([type="radio"]) {
        border: none;
        color: $form-input;
        display: block;
        height: 42px;
        margin-top: 14px;
        padding: 0 8px;
        width: 100%;
    }

    input[type="checkbox"] {
        display: inline-block;
        height: auto;
        margin-left: 20px;
        margin-right: 15px;
        margin-top: 5px;
        width: auto;
    }

    button {
        display: block;
        font-weight: 600;
        height: 44px;
        margin: 0 auto;
        max-width: 212px;
        width: 100%;

        &.btn {
            outline: none;
        }

        &.btn-back {
            display: block;
            font-size: 12px;
            font-weight: 600;
            outline: none;
            padding: 0;
            text-align: center;
            text-decoration: none;
            width: auto;

            > span::before {
                top: 1px;
            }
        }
    }


    .sideTitle {
        font-size: 34px;
        font-weight: 300;
        //line-height: 39px;
        margin-bottom: 60px;
        position: relative;
        text-align: center;

        &::after {
            border-bottom: 1px solid rgba(255,255,255,0.5);
            content: '';
            display: block;
            left: 50%;
            position: absolute;
            top: 80px;
            transform: translate(-50%, 0);
            width: 200px;
        }
    }


    .slide-wrap {
        overflow: hidden;
        position: relative;
        white-space: nowrap;
    }

    .item-row-wrap {
        display: inline-block;
        vertical-align: top;
        white-space: normal;
        width: 100%;
    }

    .slide {
        &-enter-active, &-leave-active {
            transition: all 0.4s ease;
        }

        &-enter, &-leave-to {
            opacity: 0;
        }

        &-enter-to, &-leave-to {
            transform: translateX(-100%);
        }
    }

    &.goPrev .slide {
        &-enter {
            transform: translateX(-200%);
        }

        &-enter-to {
            transform: translateX(-100%);
        }

        &-leave-to {
            transform: translateX(100%);
        }
    }

    &.summary .sideTitle {
        display: none;
    }
}

@mixin lic--min-sm() {
    .calc {
        background-image: url("/assets/images/backgrounds/calculator-lg.jpg");

        &__head {
            h1 {
                font-size: 34px;
            }
        }

        &__content, &__foot {
            max-width: 1170px;
        }

        &__content {
            padding: 0 20px;

            &__slide {
                align-items: center;
                display: flex;
                font-size: 0;
                min-height: 260px;

                .title-col, .slide-step {
                    display: inline-block;
                    font-size: 14px;
                    vertical-align: top;
                }

                .title-col {
                    width: 30%;
                }

                .slide-step {
                    width: 70%;
                }

                .field-item:first-child {
                    .list-item {
                        margin-top: 0;
                    }
                }

                .item-row {
                    .list-item {
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .lic-summary {
                &-low {
                    margin: 0 auto 60px;
                    max-width: 600px;
                    text-align: center;

                    .call {
                        text-decoration: none;
                    }
                }

                &-result {
                    font-size: 34px;
                    font-weight: 300;
                }
            }
        }

        &__foot {
            margin: 0 auto;
            padding: 60px 20px;

            .controls {
                float: right;
                min-width: 212px;

                .btn {
                    margin: 0 auto;
                }
            }
        }

        .sideTitle {
            margin-bottom: 0;
            padding: 0 30px;

            &:after {
                content: none;
            }
        }

        .slide-wrap {
            border-left: 1px solid rgba(255,255,255,0.5);
            padding: 20px 0 20px 20px;
        }

        input:not([type="checkbox"]):not([type="radio"]) {
            float: right;
            margin-top: 0;
            max-width: 212px;
        }

        [type="checkbox"], [type="radio"] {
            float: left;
            margin-bottom: 20px;
            margin-left: 0
        }

        button {

            &.btn-back {
                margin: 0 auto;
                width: auto;
            }
        }

        .st {
            &-enter-active, &-leave-active {
                transition: opacity 0.3s ease, transform 0.5s;
            }

            &-enter-to, &-leave-to {
                transform: translateY(-120%);
            }

            &-leave-to, &-enter {
                opacity: 0;
            }

            &-enter {
                transform: translateY(-50%);
            }
        }

        &.goPrev .st {
            &-enter {
                transform: translateY(-200%);
            }

            &-enter-to {
                transform: translateY(-120%);
            }

            &-leave-to {
                transform: translateY(120%);
            }
        }
    }
}
@mixin lic--min-md() {
    .calc {
        .item-row {
            .question-text {
                padding-left: 40px;
            }
        }
    }
}