.callout-nav {
    margin-bottom: -30px; // adjust for offset top position
    position: relative;
    top: -80px;
    .nav-links {
        margin: 0 auto;
        text-align: center;
    }

    .nav-link {
        margin: 40px auto 0;
        max-width: 220px;
        vertical-align: top;

        p {
            font-size: em(14);
            font-weight: 400;
            line-height: 1.36;
            margin: 0;
            padding: 10px 0;
            text-align: center;
        }

        .btn {
            background-color: $li-purple;
            border-color: $li-purple;
            display: block;
            font-weight: 600;
            line-height: 34px;
            margin: 10px auto 0;
            padding: 0;
            width: 150px;
        }
    }

    .circle {
        background-color: $light;
        display: inline-block;
        padding: 5px;
        @include border-radius(50%);

        span {
            border: 4px solid $li-purple;
            color: $li-purple;
            display: block;
            height: 147px;
            text-align: center;
            width: 147px;
            @include border-radius(50%);
        }

        i {
            font-size: em(80);
            line-height: 138px;
        }
    }
}
@mixin callout-nav--min-sm() {
    .callout-nav {
        top: -110px;
        .nav-link {
            display: inline-block;
            margin-left: 90px;
            &:first-of-type {
                margin-left: 0;
            }
        }
    }
}
@mixin callout-nav--min-md() {
    .callout-nav {
        top: -100px;
    }
}