﻿.choice__header-affix {
  left: 0;
  min-height: 48px;
  position: absolute;
  right: 0;
  top: 0;
  transition-duration: 0.01s;
  transition-property: all;
  transition-timing-function: linear;
  z-index: 1;

  &.init-scrolling {
    box-shadow: 0 0 10px 1px rgba($li-dkpurple, 0.2);
    opacity: 0;
    position: fixed;
    top: -80px;
    transition-duration: 0.15s;
    z-index: 99;

    .tel {
      display: inline-block;
    }
  }

  &.scrolling {
    background-color: $light;
    opacity: 1;
    position: fixed;
    top: 0;
    transition-duration: 0.3s;
  }
}

.choice__header {
  box-shadow: 1px 0 1px rgba(0, 0, 0, 0.2);
  height: 48px;
  padding: 9px 0;
  position: relative;

  .container-fluid {
    @include breakpoint($sm) {
      padding: 0 64px;
    }

    @include breakpoint($xg) {
      max-width: 1150px;
      padding: 0 15px;
      width: 100%;
    }
  }

  .brand {
    float: left;
    margin-left: -6px;
    margin-top: 1px;
    width: 140px;

    @include breakpoint($small) {
      margin-left: 13px;
    }
  }

  .tel {
    float: right;
    font-size: em(21);
    font-weight: 700;
    line-height: 2.2;

    @include breakpoint($xg) {
      display: none;
    }
  }

  &.affix {
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    z-index: $z-index-top;
  }

  .header-cta {
    font-size: 13px;
    font-weight: 700;
    height: 48px;
    padding: 8px 18px;
    position: absolute;
    right: 0;
    top: 0;

    @include breakpoint($small) {
      padding: 8px 26px;
    }

    @include breakpoint($sm) {
      padding: 13px 40px;
    }

    span:after {
      font-size: 18px;
      font-weight: 100;
      top: 3px;
    }
  }
}

@mixin choice__header--min-sm() {
  .choice__header-affix {
    min-height: 70px;
  }

  .choice__header {
    height: 70px;
    padding: 12px 0;

    .brand {
      margin: 0;
      width: 220px;
    }
  }
}

@mixin choice__header--min-xg() {
  .choice__header {
    .brand {
      margin-left: 0;
    }

    .tel {
      margin-right: 0;
    }
  }
}
