.choice__trustbar {
  background-color: $footer-bg;
  color: $light;
  padding: 30px 17px;
  text-align: center;

  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 0;

    @include breakpoint($sm) {
      margin: 0 auto;
      max-width: 640px;
      width: 100%;
    }

    @include breakpoint($xg) {
      max-width: 100%;
    }
  }
}
