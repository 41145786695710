.block-cta {
    border: 1px solid rgba(58, 29, 89, 0.2);
    margin: 20px 0;
    overflow: hidden;
    padding: 26px 30px 29px;

    @include breakpoint($sm) {
        padding: 30px;
    }

    &-image {
        margin-bottom: 17px;
        max-width: 70px;
        width: 100%;

        @include breakpoint($sm) {
            display: inline-block;
            margin-bottom: 0;
            max-width: 80px;
            vertical-align: top;
        }

        img {
            width: 100%;
        }
    }

    &-text {
        @include breakpoint($sm) {
            display: inline-block;
            margin-left: 26px;
            max-width: 210px;
            vertical-align: top;
            width: 100%;
        }

        @include breakpoint($xg) {
            max-width: 330px;
        }

        h3 {
            font: {
                size: 18px;
                weight: 600;
            }

            line-height: 28px;

            @include breakpoint($sm) {
                margin-bottom: 0;
            }
        }

        p {
            line-height: 24px;
            margin-bottom: 5px;
        }
    }

    &-btn {
        margin-top: 18px;

        @include breakpoint($sm) {
            float: right;
            margin-top: 9px;
            max-width: 110px;
            text-align: right;
            width: 100%;
        }
    }

    .btn {
        font: {
            size: 16px;
            weight: bold;
        }

        line-height: 26px;
        min-width: 102px;
        white-space: normal;

        &:hover, &:focus {
            text-decoration: none;
        }

        i {
            font-size: 20px;
            position: relative;
            top: 3px;
        }
    }
}
