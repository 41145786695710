.adv__form {
    @include honeypot-field;
    background-color: $lt-bg;
    padding: 50px 0;

    .final-expense {
        .request-form-branded {
            button {
                font-weight: bold;
                height: 44px;
                width: 185px;
            }
        }
    }

    .form-leadin {
        text-align: center;

        .leadin-text {
            margin: 30px auto;
            max-width: 700px;
        }
    }

    .request-form {
        margin-top: 30px;
        max-width: 320px;
        @include center-block-horizontal();
    }

    .form-leadin-branded {
        padding-left: 27px;
        padding-right: 28px;

        .form-logo {
            display: block;
            height: auto;
            margin-left: auto;
            margin-right: auto;
        }

        h2 {
            color: $li-mdpurple;
            font-size: 24px;
            font-weight: 300;
            line-height: 25px;
            text-align: center;
        }

        .leadin-text {
            color: $li-mdpurple;
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
            text-align: center;
        }
    }

    .request-form-branded {
        margin-left: 27px;
        margin-right: 28px;
        margin-top: 0;
        max-width: none;

        button {
            width: 160px;
        }

        .btn-action {
            background-color: $li-blue;
            border-color: $li-blue;
        }

        p {
            font-size: 10px;
            font-weight: 300;
            line-height: 14px;
        }

        .require-fields {
            display: block;
            font-size: 14px;
            font-weight: normal;
            padding: 20px 0;
            text-align: center;
        }
    }

    &.bg-white {
        background-color: $light;
        padding: 38px 0 10px;

        .form-leadin-branded {
            h2 {
                margin: 20px auto 0;
            }
        }
    }
}

@mixin adv__form--min-sm() {
    .adv__form {
        @include honeypot-field;
        .form-leadin {
            text-align: left;
        }

        .request-form {
            max-width: none;
        }

        .form-leadin-branded {
            padding-left: 0;
            padding-right: 0;

            .form-logo {
                height: auto;
                max-width: 390px;
                /*margin-left: 104px;*/
            }

            h2 {
                font-size: 30px;
                font-weight: 300;
                line-height: 41px;
            }

            .leadin-text {
                font-size: 18px;
                font-weight: 600;
                line-height: 24px;

                span {
                    padding-left: 40px;
                }
            }
        }

        .request-form-branded {
            margin-left: 65px;
            margin-right: 0;
            width: 450px;

            button {
                width: 156px;
            }

            form {
                label {
                    font-weight: 500;
                }

                .control-label {
                    width: 26%;
                }

                input {
                    max-width: 310px;
                }

                .input-short {
                    max-width: 140px;
                }

                .input-med {
                    max-width: 160px;
                }

                button {
                    margin-left: 26%;
                }
            }

            p {
                font-size: 10px;
                font-weight: 300;
                line-height: 14px;
            }

            .require-fields {
                padding-bottom: 8px;
                padding-right: 70px;
            }
        }

        &.bg-white {
            padding: 45px 0 0;
        }
    }
}
@mixin adv__form--min-md() {
    .adv__form {
        @include honeypot-field;
        .final-expense {
            .request-form-branded {
                .require-fields {
                    margin-top: 10px;
                }
            }
        }

        .form-leadin {
            text-align: center;
        }

        .form-leadin-branded {
            padding-left: 0;
            padding-right: 0;

            .form-logo {
                height: auto;
                /*margin-left: 337px;*/
            }

            h2 {
                font-size: 34px;
                font-weight: 300;
                line-height: 46px;
            }

            .leadin-text {
                font-size: 17px;
                font-weight: 600;
                line-height: 24px;
                max-width: 777px;
                padding-left: 151px;
                padding-right: 151px;

                span {
                    padding-left: 0;
                    padding-right: 65px;
                }
            }
        }

        .request-form-branded {
            margin-left: 5px;
            margin-right: 0;
            width: 503px;

            button {
                width: 160px;
            }

            form {
                label {
                    font-weight: 500;
                }

                .control-label {
                    width: 23%;
                }

                input {
                    max-width: 340px;
                }

                .input-short {
                    max-width: 140px;
                }

                .input-med {
                    max-width: 160px;
                }

                button {
                    margin-left: 23%;
                }
            }

            p {
                font-size: 10px;
                font-weight: 300;
                line-height: 14px;
                width: 456px;
            }

            .require-fields {
                margin-top: 30px;
                padding-right: 125px;
            }
        }

        &.bg-white {
            padding: 60px 0 0;
        }
    }
}
@mixin adv__form--min-xg() {
    .adv__form {
        .form-leadin-branded {
            .leadin-text{
                max-width: 805px;
                padding-left: 220px;
                padding-right: 80px;
            }
        }
        .request-form-branded{
            margin-left: 54px;
        }
    }
}