﻿.choice-layout {
  &::before {
    content: "";
    display: block;
    height: 48px;

    @include breakpoint($sm) {
      height: 70px;
    }
  }
}

.choice__hero {
  .hero__background {
    background: {
      repeat: no-repeat;
      size: cover;
    }

    width: 100%;

    @include breakpoint($sm) {
      padding-top: 278px;
    }
  }

  .hero__content {
    background-color: $li-purple;

    @include breakpoint($sm) {
      background-color: rgba($jh-purple, 0.8);
    }

    h1 {
      color: inherit;
      font-size: em(28);
      font-weight: 300;
      line-height: 38px;
      margin-bottom: 0;

      @include breakpoint($sm) {
        font-size: em(34);
        line-height: 45px;
      }

      p {
        margin: 0;
        padding: 0;
      }

      sup {
        font-size: 65%;
        font-weight: lighter;
        opacity: 0.75;
      }
    }

    &__title-wrapper {
      color: $light;
      padding: 64px 32px;
      position: relative;
      text-align: center;

      @include breakpoint($sm) {
        margin: 0 auto;
        max-width: 592px;
        padding: 23px 0;
        text-align: left;
        width: 100%;
      }

      @include breakpoint($xg) {
        margin: 0 auto;
        max-width: 1120px;

        h1 {
          max-width: 592px;
          width: 100%;
        }
      }
    }

    &__form-wrapper {
      position: relative;

      @include breakpoint($xg) {
        margin: 0 auto;
        max-width: 1120px;
      }
    }
  }

  .hero__form {
    @include breakpoint($sm) {
      background: $light;
    }

    @include breakpoint($xg) {
      background: transparent;
    }
  }

  .hero__logos {
    list-style: none;
    margin-bottom: 20px;
    margin-top: 15px;
    padding: 0;
    text-align: center;

    @include breakpoint($xs + 1) {
      margin-bottom: 25px;
      margin-top: 20px;
    }

    @include breakpoint($sm) {
      margin-bottom: 0;
      margin-top: 0;
      text-align: left;
    }

    li {
      display: inline-block;

      & + li {
        margin-left: 15px;

        @include breakpoint($xs + 1) {
          margin-left: 30px;
        }

        @include breakpoint($sm) {
          margin-left: 50px;
        }
      }

      img {
        max-height: 28px;
        max-width: 120px;

        @include breakpoint($xs + 1) {
          max-height: 30px;
          max-width: 130px;
        }

        @include breakpoint($sm) {
          max-height: 34px;
          max-width: 150px;
        }
        
        @include breakpoint($xg) {
          max-height: 45px;
          max-width: 208px;
        }
      }
    }
  }
}
