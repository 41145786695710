.article .related-links {
  padding-bottom: 20px;
  .link-cta {
    @include clearfix();
    padding: 30px 0;
  }
  .link-image {
    float: left;
    text-align: center;
    width: 27.5%;
  }

  img {
    height: auto;
    max-width: 133px;
    width: 100%;
    @include border-radius(50%);
  }

  .link-text {
    float: right;
    font-weight: 300;
    padding-left: 20px;
    width: 72.5%;

    .btn-sm {
      color: $light;
      line-height: 2.1;
      padding-top: 0;
      text-decoration: none;
    }
  }

  h2 {
    font-size: em(18);
    line-height: 1.4em;
    margin-top: 0;
  }
  p {
    font-size: 14px;
    margin-bottom: 14px;
  }
}
@mixin related-links--min-sm() {
  .article .related-links {
    .link-text {
      padding-left: 0;
    }
    h2 {
      font-size: 26px;
    }
  }
}
