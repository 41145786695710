.newsletter {
  color: $light;
  margin-bottom: 48px;

  @include breakpoint($sm) {
    margin-bottom: 80px;
  }

  &__inner {
    background-color: $purple-bg;
    max-width: 940px;

    .row {
      display: flex;
      flex-direction: column;

      @include breakpoint($sm) {
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  &__figure {
    max-width: 100%;

    @include breakpoint($sm) {
      max-width: 45%;
    }

    @include breakpoint($md) {
      max-width: 370px;
    }

    img {
      height: 100%;
      max-height: 220px;
      max-width: 100%;
      object-fit: cover;
      width: 100%;

      @include breakpoint($xs) {
        max-height: 270px;
      }

      @include breakpoint($sm) {
        flex-direction: row;
        max-height: 100%;
      }
    }

    figcaption {
      bottom: 0;
      max-width: 100%;
      position: absolute;
      text-indent: -9999px;
      z-index: 0;
    }
  }

  &__content {
    flex: 1;
    padding: 30px 20px;

    @include breakpoint($xs) {
      padding: 40px 35px;
    }

    @include breakpoint($sm) {
      padding: 40px 30px 35px 45px;
    }

    @include breakpoint($md) {
      padding: 55px 75px 45px;
    }

    &__title,
    &__subtitle {
      color: $light;
    }

    &__title {
      font-size: 24px;
      font-weight: 300;
      line-height: 33px;
      margin-bottom: 1em;

      @include breakpoint($sm) {
        font-size: 25.5px;
        line-height: 34px;
      }

      @include breakpoint($md) {
        font-size: 34px;
        line-height: 40px;
      }
    }

    &__subtitle {
      font-size: 15px;
      font-weight: 600;
      line-height: 20px;
      margin-bottom: 1em;

      @include breakpoint($md) {
        font-size: 20px;
        line-height: 28px;
      }
    }

    ul {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 2em;
      padding-left: 18px;

      @include breakpoint($sm) {
        font-size: 12px;
        line-height: 17px;
      }

      @include breakpoint($md) {
        font-size: 14px;
        line-height: 24px;
      }
    }

    .btn {
      font-size: 16px;
      font-weight: 600;
      min-height: 44px;
      padding: 10px 15px;
      width: 100%;

      @include breakpoint($sm) {
        height: auto;
        width: auto;
      }
    }
  }
  
  &__form {
    &__controls {
      display: flex;
      flex-direction: column;
      position: relative;

      @include breakpoint($sm) {
        flex-direction: row;
      }

      .control {
        flex: 1;
        margin-bottom: 9px;
        position: relative;

        @include breakpoint($sm) {
          margin-bottom: 0;
        }
      }
    }

    &__input[type="text"] {
      border: 0;
      color: $select-text;
      font-size: 12px;
      font-weight: 600;
      height: 44px;
      max-width: 100%;
      padding: 0 5px;
      text-align: center;
      width: 100%;

      &:focus + label,
      &.not-empty + label {
        color: inherit;
        left: 50%;
        top: -12px;

        @include breakpoint($sm) {
          left: 5px;
        }
      }

      @include breakpoint($sm) {
        text-align: left;
      }
    }

    &__label {
      color: $select-text;
      font-size: 12px;
      font-weight: 600;
      left: 50%;
      margin-bottom: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      transition: all .3s ease-in-out 0s;

      @include breakpoint($sm) {
        left: 15px;
        transform: translateY(-50%);
      }
    }

    &__disclaimer {
      color: $light-alt;
      display: block;
      font-size: 13px;
      font-weight: 300;
      line-height: 17px;
      margin-top: 1em;

      @include breakpoint($sm) {
        font-size: 11px;
        line-height: 15px;
      }

      @include breakpoint($md) {
        line-height: 32px;
      }
    }
  }
}