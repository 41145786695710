.direct-hero {

	.container {
		margin: 0;
		margin: 0 auto; 
		max-width: 1280px;
		padding: 0;

		@include breakpoint($sm) {
			align-items: center;
			display: flex;
			flex-direction: row;
			max-height: 288px;
			width: 100%;
		}

		@include breakpoint($lg) {
			max-height: 413px;
		}
	}

	.content {
		color: white;
		margin: 0 auto;
		padding: 32px 45px;

		@include breakpoint($sm) {
			flex-basis: 50%;
    		flex-grow: 1;
			padding: 32px 0 32px 15px;
			
		}
		@include breakpoint($lg) {
			flex-basis: 50%;
    		flex-grow: 1;
			padding: 32px 0 32px 95px;
			
		}

		.btn {
			font-size: 16px;
			font-weight: 600;
			padding: 17px 41px;
		}


		h1 {
			color: white;
			font-size: 24px;
    		font-weight: 200;
			line-height: 30px;
			margin-bottom: 18px;

			@include breakpoint($sm) {
				max-width: 295px;
			}

			@include breakpoint($lg) {
				font-size: 34px;
				line-height: 40px;
				max-width: 450px;
			} 
		}

		p {
			font-size: 14px;
			font-weight: normal;
			line-height: 19px;
			margin-bottom: 23px;

			
			@include breakpoint($sm) {
				font-size: 16px;
				line-height: 20px;
				max-width: 330px;
			} 

			@include breakpoint($lg) {
				font-size: 22px;
				line-height: 28px;
				max-width: 470px;
			} 
		}
	}

	.image {
		overflow: hidden;

		@include breakpoint($sm) {
			flex-basis: 50%;
    		flex-grow: 1;
		}

		img {
			display: none;
			height: 100%;
			width: auto;
 
			@include breakpoint-interval($xs,($sm - 1px)) {
				height: auto;
				width: 100%;
				
				&.mobile {
					display: block;
				}
			}

			@include breakpoint-interval($sm ,($lg - 1)) {
				float: left;

				&.tablet {
					display: block;
					margin-left: -3px;
				}
			}

			@include breakpoint($lg) {
				&.desktop {
					display: block;
				}
			}
		}
	}
}