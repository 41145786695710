.direct-page {
	padding-top: 121px;

	@include breakpoint($sm) {
		padding-top: 97px;
	}

	@include breakpoint($lg) {
		padding-top: 100px;
	}

	@import "./cards";
	@import "./cta";
	@import "./header";
	@import "./hero";
	@import "./people";
	@import "./two-columns";
	@import "./trust-banner";
	}
