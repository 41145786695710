/* stylelint-disable */
@font-face {
  font-family: "li-org";
  font-display: swap;
  src:
    url('../fonts/li-org.eot?1mrcwz'),
 	url('../fonts/li-org.eot?1mrcwz#iefix') format('embedded-opentype'),
	url("../fonts/li-org.woff2?1mrcwz") format("woff2"),
	url("../fonts/li-org.ttf?1mrcwz") format("truetype"),
	url("../fonts/li-org.woff?1mrcwz") format("woff"),
	url("../fonts/li-org.svg?1mrcwz#li-org") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "li-org" !important;
  font-display: swap;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
$icons: (
	chevron-right: "\e900",
	chevron-left: "\e91c",
	chevron-last: "\e91e",
	chevron-first: "\e91d",
	arrows: "\e913",
	calculator: "\e92f",
	calculator2: "\e933",
	check-circle: "\e953",
	compare: "\e952",
	gears: "\e94f",
	medical-case: "\e94e",
	reading: "\e94b",
	magnifying-glass: "\e949",
	nbc: "\e948",
	oz: "\e944",
	forbes: "\e942",
	f: "\e941",
	g: "\e93e",
	n: "\e93c",
	s: "\e93b",
	p: "\e93d",
	t: "\e939",
	u: "\e937",
	v: "\e935",
	w: "\e936",
	t-p: "\e954",
	v-u: "\e951",
	facebook: "\e950",
	twitter: "\e94d",
	linkedin: "\e94c",
	gplus: "\e947",
	moo-logo: "\e943",
	moo-logo2: "\e93c",
	aetna-logo: "\e93f",
	jh-logo: "\e938",
	humana-logo: "\e934",
	aig-logo: "\e955",
	mass-logo: "\e956",
	arrow-right: "\e945",
	right-direction: "\e91f",
	money: "\e928",
    phone: "\e92b",
    clipboard: "\e92a",
    moneybag: "\e929",
    norton: "\e94a",
    bbb: "\e946",
    secure-website: "\e968",
    jh-vitality: "\e940",
    call-center: "\e930",
    choices: "\e931",
    heart-usa: "\e932",
    rewards: "\e969"
);

/* Create class for each icon in the list */
@each $name, $value in $icons {
	.icon-#{$name}:before {
		content: $value;
	}
}