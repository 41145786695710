.direct-two-columns {
	display: block;

	.container {

		@include breakpoint($sm) {
			display: flex;
			flex-direction: row;
			width: 100%
		}

		@include breakpoint($md) {
			max-width: 1170px;
		}
	}

	&.agent {
		padding: 53px 20px 49px;

		@include breakpoint($sm) {
			padding: 60px 24px 45px;
		}

		@include breakpoint($md) {
			padding-bottom: 89px;
			padding-top: 87px;
		}

		.container {
			max-width: 803px;
		}

		.left-column {
			text-align: center;
			width: 100%;

			img {
				height: auto;
				width: 100%;
			}

			.left-image-mobile {
				display: block;
				margin: 0 auto;
				max-width: 283px;
			}

			.left-image-tablet-desktop {
				display: none;
			}

			@include breakpoint($sm) {
				width: 50%;

				.left-image-mobile {
					display: none;
				}

				.left-image-tablet-desktop {
					display: block;
					max-width: 350px;
				}
			}

			.left-text{
				@include breakpoint($sm) {
					width: 350px;
				}
				
				.call {
					color: $li-ltgray-alt;
					font-family: $font-family-sans-serif;
					line-height: 24px;
					margin: 15px auto 0 auto;
					width: 280px;
	
					@include breakpoint($sm) {
						margin: 15px auto 0 auto;
					}
				}	
			}
		}

		.right-column {
			padding-top: 29px;
			width: 100%;

			@include breakpoint($sm) {
				padding-top: 38px;
				width: 50%;
			}

			@include breakpoint($md) {
				padding-top: 49px;
			}

			.title {
				color: $li-dkpurple-alt;
				font-size: 34px;
				line-height: 46px;
				text-align: center;

				strong {
					font-weight: 800;
				}

				@include breakpoint($sm) {
					text-align: left;
				}
			}

			.divider {
				border-top: 5px solid $li-mdgray-alt;
				height: 1px;
				margin-top: 22px;
				width: 290px;

				@include breakpoint($sm) {
					width: 270px;
				}

				@include breakpoint($md) {
					margin: 32px 0;
					width: 364px;
				}
			}

			.call {
				color: $li-ltgray-alt;
				font-size: 18px;
				font-weight: 400;
				line-height: 22px;
				margin: 22px auto 0;
				text-align: center;
				width: 240px;

				@include breakpoint($sm) {
					line-height: 22px;
					text-align: left;
					width: 270px;
				}

				@include breakpoint($md) {
					width: 364px;
				}
			}

			.tfn {
				color: $li-ltblue;
				font-size: 34px;
				font-weight: bold;
				line-height: 54px;
				margin: 10px auto 0;
				text-align: center;

				.is--cellphone:hover{
					color: $li-blue;
				}

				@include breakpoint($sm) {
					text-align: left;
				}

				@include breakpoint($md) {
					margin-top: 0;
				}
			}

			.tty {
				color: $li-ltgray-alt-2;
				font-size: 22px;
				font-weight: 300;
				line-height: 24px;
				text-align: center;

				@include breakpoint($sm) {
					text-align: left;
				}

			}

			.right-text {
				margin: 0 auto;
				width: 290px;

				@include breakpoint($sm) {
					margin-left: 67px;
					width: 270px;
				}

				@include breakpoint($md) {
					margin-left: 31px;
					width: 364px;
				}
			}
		}
	}

	&.average {
		background: $li-purple;

		.left-column {
			color: $light;
			margin: 20px auto;
			padding: 55px 0 28px;
			text-align: left;
			width: 290px;

			.title {
				color: $light;
				font-size: 52px;
				font-weight: 300;
				line-height: 65px;
				margin: 0 auto;

				strong {
					font-weight: bold;
				}

				sup {
					font-size: 15px;
					font-weight: 600;
					left: -9px;
					top: -2em;
				}

				@include breakpoint($sm) {
					text-align: left;
					width: 420px;
				}

				@include breakpoint($md) {
					width: 540px;
				}
			}

			.text {
				font-size: 22px;
				font-weight: 300;
				line-height: 30px;
				margin: 30px auto 0;

				@include breakpoint($sm) {
					margin: 40px auto 0;
					width: 420px;
				}

				@include breakpoint($md) {
					width: 540px;
				}
			}

			@include breakpoint($sm) {
				margin: 50px 10px 0 auto;
				padding: 55px 35px 105px 35px;
				width: 60%;
			}

			@include breakpoint($md) {
				padding: 102px 35px 30px 100px;
			}
		}

		.right-column {
			text-align: center;
			width: 100%;

			@include breakpoint($sm) {
				padding-top: 41px;
				width: 40%;
			}
		}
	}
}
