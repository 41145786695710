﻿.trust-banner {
    background-color: $li-purple;
    color: $light;
    font-size: 14px;
    padding: 12px 0;
    text-align: center;
    p {
        margin: 0;
    }
    sup {
        font-size: 60%;
        top: -0.75em;
    }
}
// shared styles for co-branded page
.trust-banner-branded {
    p {
        margin: 0 43px;
    }
}
@mixin trust-banner--min-md() {
    .trust-banner {
        font-size: 16px;
    }
}