﻿.direct-cards {
    margin-bottom: 64px;
    margin-top: 64px;

    @include breakpoint($sm) {
        margin-bottom: 93px;
        margin-top: 93px;
    }

    @include breakpoint($lg) {
        margin-bottom: 87px;
        margin-top: 87px;
    }

    .row {
        text-align: center;

        .card {
            margin-bottom: 60px;

            @include breakpoint($sm) {
                margin-bottom: 0;
            }
        }

        .card-main-title {
            margin-bottom: 52px;

            @include breakpoint($sm) {
                margin-bottom: 30px;
            }

            @include breakpoint($lg) {
                margin-bottom: 38px;
            }

            h2 {
                color: $li-card-title;
                font-size: 34px;
                font-weight: 300;
                line-height: 34px;
            }
        }

        .card-image {
            height: 198px;
            margin-bottom: 30px;
            width: 198px;

            @include breakpoint($sm) {
                height: 127.84px;
                width: 127.6px;
            }

            @include breakpoint($lg) {
                height: 198px;
                width: 198px;
            }
        }

        .card-title {
            margin: auto;
            margin-bottom: 22px;
            width: 131px;

            h3 {
                color: $li-card-subtitle;
                font-size: 22px;
                font-weight: bold;
                line-height: 26px;
            }
        }

        .card-description {
            margin: auto;
            width: 270px;

            @include breakpoint($sm) {
                width: 180px;
            }

            @include breakpoint($lg) {
                width: 270px;
            }

            p {
                color: $li-ltgray-alt-2;
                font-size: 16px;
                font-weight: 400;
                height: 79px;
                line-height: 26px;
            }
        }
    }
}
