.component__percentage {

    &.is--shaded {
        background: $lt-bg;
    }

    .percentage__wrap {
        padding: 50px 15px;
    }

    &.single {
        .percentage__wrap {
            padding: 10px 15px;
        }
        .percentage__text-primary {
            color: $dark;
            padding-top: 1em;
            sup {
                font-size: 1em;
                top: -0.5em;
            }
        }
    }

    h2, .icon:before {
        color: $li-ltpurple;
    }

    h2 {
        font-weight: 300;
        margin-bottom: 36px;
    }

    h2:empty,
    div:empty {
        display: none;
    }

    sup {
        font-size: 60%;
        top: -0.75em;
    }

    .icon:before {
        display: block;
        font-size: 72px;
        margin-bottom: 20px;
    }

    .percentage__text-primary {
        font-size: 18px;
        font-weight: 400;
    }

    .percentage__text-secondary {
        font-size: 26px;
        font-weight: 600;
        line-height: 1.3;
        margin-top: 36px;
    }
}

@mixin percentage--min-sm() {
    .component__percentage {
        .percentage__wrap {
            margin: 0 auto;
            max-width: 640px;
            padding: 80px 0;
            text-align: center;
        }

        .icon:before {
            font-size: 82px;
        }
        h2 {
            line-height: 1.2;
        }
        .percentage__text-primary {
            font-size: 18px;
            font-weight: 700;
            line-height: 24px;
        }
        .percentage__text-secondary {
            font-size: 26px;
            font-weight: 600;
        }

        &.policies {
            .percentage__wrap {
                max-width: 780px;
            }
        }
    
        &.single {
            .percentage__wrap {
                max-width: 590px;
                padding: 0;
            }
            .percentage__text-primary {
                font-size: 16px;
                font-weight: 400;
            }
        }
    }
}