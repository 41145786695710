.adv__ribbon-banner {
  background-color: $ribbon-bg;
  color: $li-purple;
  font-size: em(14);
  font-weight: 600;
  text-align: center;
  > p {
    margin-bottom: 0;
    padding: 20px;
  }
}