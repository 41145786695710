.not-found {
    padding: 40px 15px;
  text-align: center;
  .logo {
      margin: 0 auto;
      max-width: 220px;
  }
  .image {
      margin: 0 auto 60px;
      img {
          max-width: 330px;
          width: 100%;
      }
  }
  .text {
      font-weight: 300;
      margin: 0 auto;
      max-width: 410px;
      h1 {
          font-weight: 300;
      }
      p, a {
          color: $li-purple;
      }
      p {
          margin-top: 20px;
      }
      a {
          text-decoration: underline;
      }
  }
}
.not-found-page {
    .site-nav {
        box-shadow: none;
    }
}