.subscribe {
    background-color: $purple-bg;
    color: $light-alt;
    padding: 40px 0;
    text-align: center;
    h3 {
        color: $light;
        font-size: em(16);
        font-weight: 300;
        line-height: em(22);
        margin-bottom: 2px;
    }

    h4 {
        color: $light;
        font-size: em(16);
        font-weight: 300;
        line-height: em(22);
        margin-bottom: 30px;
    }

    form {
        margin: 0 auto;
        max-width: 320px;
        position: relative;

        input {
            border: none;
            color: $form-input;
            display: inline-block;
            font-size: 16px;
            height: 39px;
            padding-left: 8px;
            padding-right: 8px;
            width: calc(100% - 90px);
            @include placeholder-label();
            + label {
                left: 25px;
            }
            &:focus + label, 
            &:valid + label, 
            &.not-empty + label {
                left: 7px;
            }
        }

        .field-validation-error,
        .field-validation-valid,
        .field-validation-error span {
            color: red;
            font-size: 12px;
        }

        .btn {
            display: inline-block;
            font-size: em(12);
            font-weight: 600;
            height: 39px;
            margin-left: -5px;
            vertical-align: top;
            width: 76px;

            &.btn-action {
                background-color: $li-green;
                border-color: $li-green;
            }
        }

        small {
            display: block;
            font-size: em(8);
            font-weight: 300;
            margin-top: 11px;
        }
    }
}
@mixin subscribe--min-sm() {
  .subscribe {
    padding: 40px 0 34px;
    .form-title {
      text-align: center;
    }
    h3 {
      font-size: em(18);
      font-weight: 100;
    }
    h4 {
      font-size: em(18);
      font-weight: 100;
      margin-bottom: 24px;
      padding-left: 18px;
    }
    form {
      margin-left: auto;
      margin-right: auto;
      max-width: 360px;
      input {
        max-width: none;
        width: 260px;
      }
      .btn {
        width: 84px;
      }
      small {
        font-size: em(10);
      }
    }
  }
}
@mixin subscribe--min-lg() {
  .subscribe {
    padding: 60px 0 50px;

    .subscribe-col {
      float: none;
      margin: 0 auto;
      width: auto;
      @include clearfix();
    }

    .form-title {
      display: inline-block;
      margin-top: -6px;
      text-align: right;
      vertical-align: middle;
    }

    h3 {
      font-size: em(20);
      margin-bottom: 0;
    }

    h4 {
      display: block;
      font-size: em(20);
      padding-left: 0;
    }

    form {
      display: inline-block;
      margin-left: 30px;
      max-width: 515px;
      vertical-align: middle;

      input {
        width: 370px;

        + label {
          left: 15px;
        }

        &:focus + label, 
        &:valid + label, 
        &.not-empty + label {
          left: 4px;
        }
      }

      .btn {
        width: 140px;
      }

      small {
          padding-left: 15px;
          text-align: left;
      }
      .field-validation-error {
          padding-left: 12px;
          text-align: left;
      }
    }
  }
}