.compare-table {
  margin-top: 60px;
  tr {
    & > th {
      padding-right: 20px;
      width: 14%;
    }
    & > th:nth-of-type(1) {
      width: 5%;
    }
    & > th:nth-of-type(4) {
      text-align: center;
    }    
    & > th:nth-of-type(5),
    & > th:nth-of-type(6) {
      width: 18%;
    }
    & > td:nth-of-type(1) {
      font-size: em(45);
      padding-top: 2px;
    }
    & > td:nth-of-type(2) {
      font-weight: 600;
      small {
        display: block;
      }
    }
    & > td:nth-of-type(4) {
      text-align: center;
    }
    td {
      padding: 20px 20px 50px 0;
      &:first-child {
        padding-left: 15px;
      }
    }
  }
}