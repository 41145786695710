.error-page {
  .logo img {
    height: auto;
    max-width: 230px;
    width: 100%;
  }
  .image {
    margin: 50px 0;
    overflow: hidden;
    img {
      height: auto;
      left: 10px;
      max-width: 329px;
      position: relative;
      width: 100%;
    }
  }
  h1 {
    font-size: em(42);
    font-weight: 300;
  }
  p {
    color: $li-purple;
    font-size: em(17);
    & > a {
      color: inherit;
      text-decoration: underline;
    }
  }
}