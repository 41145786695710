.pagination {
    margin: 60px 0;
    ul {
        margin: 0;
        padding: 0;
        li {
            display: inline-block;
            vertical-align: top;
            a {
               color: $li-blue;
               font-weight: 600;
               text-decoration: none;
            }
            &:first-child {
                padding-left: 0;
            }
            &.active {
                a {
                    color: $li-ltgray;
                    font-weight: 600;
                    pointer-events: none;
                }
            }
        }
    }
}