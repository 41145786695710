.choice {
  &__content {
    background: $lt-bg;
    padding-bottom: 24px;
    text-align: center;

    @include breakpoint($sm) {
      padding-bottom: 48px;
    }

    @include breakpoint($xg) {
      background: $light;
      padding-bottom: 170px;
    }

    &-wrapper {
      @include breakpoint($xg) {
        margin: 0 auto;
        max-width: 1120px;
      }
    }

    sup {
      font-size: 60%;
      font-weight: 300;
    }

    h2 {
      color: $li-mdpurple;
      font-size: 28px;
      font-weight: 300;
      line-height: 38px;
      margin-bottom: 30px;
      padding: 0 32px;
      
      @include breakpoint($sm) {
        font-size: em(35);
        line-height: 48px;
        margin: {
          left: auto;
          right: auto;
        };
        max-width: 640px;
        padding: 0;
        text-align: center;
        width: 100%;
      }

      @include breakpoint($xg) {
        margin-left: 0;
        max-width: 545px;
        text-align: left;
      }
    }

    h3 {
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
    }

    h4 {
      color: $li-mdgray;
      font-size: em(18);
      line-height: 1.4;
      margin-bottom: 20px;

      @include breakpoint($sm) {
        font-size: em(20);
      }
    }

    p {
      color: $text-primary;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      padding: 0 32px;

      @include breakpoint($sm) {
        margin: {
          left: auto;
          right: auto;
        };
        max-width: 640px;
        padding: 0;
        text-align: center;
        width: 100%;
      }

      @include breakpoint($xg) {
        margin-left: 0;
        max-width: 545px;
        text-align: left;
      }
    }

    ul {
      margin-bottom: 30px;
      margin-left: -20px;
      text-align: left;

      li {
        padding-left: 15px;

        & + li {
          margin-top: 15px;
        }
      }
    }

    a[href^="tel:"]:hover {
      color: $li-blue;
    }

    .bg-white {
      background: $light;
      padding: 64px 0 48px;

      @include breakpoint($sm) {
        padding: 72px 0 61px;
      }

      @include breakpoint($xg) {
        padding: 80px 0 25px;
      }
    }

    .bg-gray {
      padding: 48px 0 0;

      @include breakpoint($sm) {
        padding: 72px 0 32px;
      }

      @include breakpoint($xg) {
        padding: 14px 0 40px;
      }

      h2 {
        margin: 0;

        @include breakpoint($sm) {
          font-size: 34px;
          line-height: 40px;
          margin: {
            left: auto;
            right: auto;
          };
        }

        @include breakpoint($xg) {
          margin-left: 0;
          max-width: 545px;
          text-align: left;
        }
      }
    }

    .block-cta {
      background: $light;
      border: 2px solid $border-lt;
      margin: 24px 32px;
      padding: 0;

      @include breakpoint($sm) {
        align-items: center;
        display: flex;
        margin: 0 auto 24px;
        max-width: 640px;
        width: 100%;
      }

      @include breakpoint($xg) {
        margin: 0 0 40px;
        max-width: 545px;

        &:first-of-type {
          .block-cta-image {
            img {
              height: 80px;
            }
          }
        }
      }

      &-image {
        margin: 0 auto;
        max-width: 100%;
        padding: 44px 0 25px;

        @include breakpoint($sm) {
          margin: 0;
          max-width: 141px;
          padding: 0;
        }

        @include breakpoint($xg) {
          max-width: 210px;
        }

        img {
          height: 79px;
          width: auto;

          @include breakpoint($sm) {
            height: 70px;
          }
        }
      }

      &-text {
        background: $border-lt;
        color: $text-primary;

        @include breakpoint($sm) {
          align-items: center;
          display: flex;
          margin: 0;
          max-width: 498px;
          min-height: 120px;
          text-align: left;
          width: 100%;
        }

        p {
          font-size: 20px;
          font-weight: 400;
          line-height: 30px;
          margin: 0;
          padding: 40px 42px;

          @include breakpoint($sm) {
            padding: 30px 35px;
            text-align: left;
          }

          @include breakpoint($xg) {
            padding: 50px 38px;
          }

          sup {
            font-weight: 400;
          }
        }
      }
    }
  }
}
