.article {
    & + .article {
        margin-top: 60px;
    }

    h1 {
        font-size: em(24);
        font-weight: 400;
        line-height: 1.3em;
        margin-bottom: 0.75em;
    }

    h2 {
        font-size: em(22);
        font-weight: 400;
        line-height: 1.3em;
        margin-top: 0.75em;
    }

    h3 {
        font-size: em(20);
        font-weight: 400;
        line-height: 1.3em;
    }

    p {
        margin: 0 0 1.5em;
    }

    .section-heading {
        color: $li-dkpurple;
        display: block;
        font-size: 1.05em;
        font-weight: 800;

        &:before {
            border-bottom: 1px solid $content-border;
            content: " ";
            display: block;
            margin: 30px 0 10px;
            width: 80%;
        }
    }

    ul {
        margin-bottom: 1.5em;
        padding-left: 20px;

        li {
            margin-bottom: 15px;
            padding-left: 10px;

            a {
                color: $li-blue;
            }
        }

        &.breadcrumb {
            margin: -20px 0 20px;
            padding: 0;

            li {
                color: $li-ltgray;
                display: inline-block;
                font-size: 12px;
                margin-bottom: 6px;
                padding: 0;
                text-transform: lowercase;
                vertical-align: top;

                span {
                    margin: 0 10px;
                }

                a {
                    color: $li-ltgray;
                    text-decoration: none;

                    &:hover,
                    &:focus {
                        color: $li-blue;
                        text-decoration: underline;
                    }
                }

                &:first-letter {
                    text-transform: uppercase;
                }
            }
        }
    }

    table {
        border: 0;

        tr {
            td {
                border-left: 0;
                border-right: 0;
            }

            &:last-child {
                td {
                    border-bottom: 0;
                }
            }
        }
    }

    .link-list {
        padding-left: 0;

        & > li {
            padding-left: 0;
        }
    }

    a {
        color: $li-blue;
        font-weight: 400;

        &:hover,
        &:focus {
            color: darken($li-blue, 20%);
            text-decoration: underline;
        }

        &.is--cellphone:hover {
            color: $li-blue;
        }
    }

    .company-description {
        background-color: $lt-bg;
        padding: 40px;

        .logo {
            color: $li-ltgray;
            display: inline-block;
            font-size: 2.25em;
            margin-bottom: 6px;
        }
    }

    .ins-type-icons {
        background-color: $lt-bg;
        color: $li-purple;
        padding-bottom: 6px;
        padding-top: 26px;
        text-align: center;

        li {
            display: inline-block;
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 20px;
            margin-right: 36px;
            text-align: center;
        }

        i {
            display: block;
            font-size: 50px;
            margin-bottom: 6px;
        }

        &.alternate li:nth-of-type(even) {
            color: $li-blue;
        }
    }

    dl {
        dt {
            color: $li-dkpurple;
            font-weight: 600;
            margin-bottom: 0.75em;
            margin-top: 1.75em;
        }

        dd {
            line-height: 1.6;
        }
    }

    .custom-ol {
        // this styling works best with 1-2 digit numbers
        counter-reset: custom-ol-counter;
        list-style-position: inside;
        list-style-type: none;
        padding-left: 0;

        li {
            position: relative;

            &:before {
                color: $li-mdpurple;
                content: counter(custom-ol-counter);
                counter-increment: custom-ol-counter;
                display: block;
                float: left;
                font-size: em(10);
                font-weight: 600;
                line-height: 20px;
                margin-right: 6px;
                padding-top: 3px;
                text-align: center;
                width: 20px;
            }

            &:after {
                border: 2px solid $li-mdpurple;
                content: " ";
                display: inline-block;
                height: 20px;
                position: absolute;
                top: 3px;
                width: 20px;
                @include border-radius(50%);
            }

            & + li {
                margin-top: 24px;
            }
        }

        strong {
            color: $li-mdpurple;
        }

        p {
            margin: 10px 0 0;
        }
    }

    &.reference-page {
        padding-bottom: 40px;
    }

    img {
        height: auto;
        max-width: 100%;
    }

    &.organic-carrier-page {
        h2 {
            font-size: em(24);
            line-height: em(30);
        }

        img {
            padding-bottom: 25px;
            padding-top: 12px;
            width: 250px;
        }

        .legal {
            p {
                color: #7C7C7C;
                font-size: em(12);
            }
        }
    }
}
@mixin article--min-sm() {
  .article {
    padding-right: 2%;
    h1 {
      font-size: em(34);
      font-weight: 300;
    }
    h2 {
      font-size: em(30);
      font-weight: 300;
      margin-top: 40px;
    }
    h3 {
      font-size: em(26);
      font-weight: 300;
    }
    ul {
        &.breadcrumb {
            margin: -40px 0 20px;
        }
    }
    &.organic-carrier-page {
        hr {
            margin-left: 0;
            width: 75%;
        }
    }
  }
}
@mixin article--min-lg() {
    .article {
        &.has-border:before {
            background: $content-border;
            content: "";
            height: 700px;
            left: -15px;
            position: absolute;
            width: 1px;
        }
    }
}
