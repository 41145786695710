.li-explained {
  padding-top: 0;

  .site-nav {
    background-color: $li-purple;
    box-shadow: none;
    margin-bottom: 0;
    transition: background-color 0.2s linear;

    .navbar-toggle {
      color: $light;
      font-size: 9px;
      font-weight: 800;
      margin: 0;
      text-transform: uppercase;
    }

    &.navbar-default .navbar-toggle .icon-bar {
      background-color: $light;
    }

    .navbar-nav > li > a {
      color: $light;
    }

    &.sticky {
      background-color: $li-purple;
    }
  }

  .header-hero {
    background-color: $li-blue;
    background-position: 43%;
    //background-image: url("/assets/images/hero/explained.jpg");
    background-size: cover;
    color: $light;

    .hero-text {
      margin: 100px auto;
      max-width: 580px;
      text-align: center;
    }

    h1 {
      color: inherit;
      font-size: em(40);
      font-weight: 300;
      margin-bottom: 24px;
    }

    p {
      font-weight: 300;
    }
  }

  main {
    ul {
      margin-bottom: 1.5em;
      padding-left: 20px;

      li {
        line-height: 1.8;
        padding-left: 10px;
      }
    }

    p {
      margin: 0 0 1.5em;
    }

    h2 {
      font-size: em(24);
      font-weight: 400;
    }
  }

  .page-nav {
    margin-bottom: -30px; // adjust for offset top position
    position: relative;
    top: -80px;
    .nav-links {
      margin: 0 auto;
      text-align: center;
    }

    .nav-link {
      display: inline-block;
      margin-top: 40px;
      max-width: 165px;
      vertical-align: top;
      width: 48%;

      p {
        font-size: em(14);
        font-weight: 400;
        margin: 0;
        padding: 10px 20px;
        text-align: center;
      }

      .btn {
        display: block;
        line-height: 34px;
        margin: 0 auto;
        padding: 0;
        width: 127px;
      }
    }

    .circle {
      background-color: $light;
      display: inline-block;
      padding: 5px;
      @include border-radius(50%);

      span {
        border: 3px solid;
        display: block;
        height: 94px;
        text-align: center;
        width: 94px;
        @include border-radius(50%);

        &.black {
          border-color: $dark;
          color: $dark;
        }

        &.pink {
          border-color: $li-pink;
          color: $li-pink;
        }

        &.blue {
          border-color: $li-blue;
          color: $li-blue;
        }
      }

      i {
        font-size: em(60);
        line-height: 88px;
      }
    }

    .help-text {
      font-weight: 400;
      margin-bottom: 0;
      padding: 40px 8% 0;
      text-align: center;
    }
  }

  .what-is-li {
    .lead-in {
      background-color: $row-bg;
      margin-bottom: 50px;
      padding: 50px 0 30px;

      h2 {
        margin-bottom: 36px;
        text-align: center;
      }

      p {
        margin: 24px auto;
        max-width: 518px;
      }
    }

    .content {
      padding: 0 0 50px;

      div {
        margin: 0 auto 30px;
        max-width: 468px;
        position: relative;

        &:before {
          color: $li-purple;
          display: block;
          font-family: li-org;
          font-size: em(40);
        }

        &.type-p:before {
          content: "\e93d";
        }

        &.type-t:before {
          content: "\e939";
        }

        strong {
          color: $li-purple;
        }

        .btn {
          display: block;
          font-weight: 600;
          margin-top: 18px;
          max-width: 174px;
        }
      }
    }
  }

  .compare {
    padding-bottom: 40px;

    h2 {
      text-align: center;
    }

    p {
      margin: 20px auto;
      max-width: 685px;
      text-align: center;
    }

    .compare-table {
      font-size: 14px;
      margin-top: 30px;
    }

    thead tr,
    tr:nth-of-type(even) {
      background-color: $row-bg;
    }
  }

  .coverage-needs {
    padding: 60px 0;

    h2 {
      margin-bottom: 30px;
    }

    .math {
      background-color: $row-bg;
      font-size: em(14);
      padding: 50px;

      p:last-of-type {
        margin-bottom: 0;

        &:before {
          background-color: $dark;
          content: "";
          display: block;
          height: 1px;
          margin: 30px 0;
          max-width: 175px;
          width: 75%;
        }
      }
    }
  }

  .article-content {
    padding: 60px 0;

    article + article {
      margin-top: 80px;
    }

    .is--cellphone:hover {
      color: $li-blue;
    }
    //.company-ratings-list {
    //  list-style: none;
    //  padding-left: 0;
    //  li {
    //    position: relative;
    //    padding-left: 40px;
    //    font-weight: 400;
    //    padding-bottom: 6px;
    //    &:last-of-type {
    //      padding-bottom: 0;
    //    }
    //    strong:first-child {
    //      position: absolute;
    //      top: 0;
    //      left: 0;
    //      display: inline-block;
    //      width: 40px;
    //    }
    //  }
    //}
  }
}
@mixin li-explained--min-sm() {
  .li-explained {
    .site-nav {
      min-height: 65px;
      .navbar-brand {
        padding-top: 22px;
        img {
            width: 200px;
        }
      }
      .navbar-toggle {
        font-size: 17px;
        margin-bottom: 12px;
        margin-top: 12px;
        .icon-bar {
          height: 4px;
          width: 52px;
          & + .icon-bar {
            margin-top: 8px;
          }
        }
      }
      .is--cellphone a {
          color: $light;
      }
    }
    .header-hero {
      background-position: 50%;
      .hero-text {
        margin: 200px auto 100px;
      }
      h1 {
        font-size: em(48);
      }
    }
    .page-nav {
      .nav-link {
        margin-left: 36px;
        margin-right: 36px;
        .btn {
          width: 119px;
        }
      }
      .circle {
        span {
          height: 127px;
          width: 127px;
        }
        i {
          font-size: em(80);
          line-height: 120px;
        }
      }
      .help-text {
        margin: 0 auto;
        max-width: 542px;
        padding-left: 0;
        padding-right: 0;
      }
    }
    main {
      h2 {
        font-size: em(34);
        font-weight: 300;
      }
      & .lic h2 {
        font-size: 14px;
        font-weight: 400;
      }
    }
    .what-is-li {
      .content {
        div:before {
          display: inline-block;
          left: -54px;
          position: absolute;
          top: -8px;
        }
      }
    }
  }
}
@mixin li-explained--min-md() {
  .li-explained {
    .site-nav {
      .navbar-collapse {
        background-color: transparent;
      }
      .navbar-brand {
        padding-top: 10px;
      }
      .navbar-nav {
        & > li > a {
          height: 69px;
          line-height: 69px;
          &:hover,
          &:focus {
            background-color: $light;
            color: $li-blue;
          }
                    
        }
        & > .dropdown > a {
            line-height: 67px;
        }
        .dropdown.open {
          & > a,
          & > a:focus {
            background-color: $site-nav-bg;
            color: $light;
          }
        }
        & .last-item {
            margin-top: 23px;
            
            .is--cellphone {
              a:hover {
                color: $light;
              }
            }
        }
      }
    }
    .coverage-needs {
      .math {
        margin-top: -60px;
      }
    }
  }
}