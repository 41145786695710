.direct-cta {
    position: relative;
    z-index: 1;

    .cta-background-image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        position: absolute;
        width: 100%;
        z-index: -1;
    }

    .cta-title {
        color: white;
        margin-bottom: 30px;
        margin-top: 62px;

        @include breakpoint($sm) {
            margin-top: 75px;
        }

        p {
            font-size: 34px;
            font-weight: 300;
            line-height: 46px;
            margin: auto;
            text-align: center;
            width: 290px; 

            @include breakpoint($sm) {
                width: 490px;
            }
        }
    }

    .cta-btn {
        display: block;
        font-size: 16px;
        font-weight: bold;
        line-height: 39px;
        margin: auto;
        margin-bottom: 59px;
        max-width: 200px;
        padding: 8px 38px 9px 39px;
        width: fit-content;

        @include breakpoint($sm) {
            margin-bottom: 95px;
        }
    }
}
