.adv__apply-info {
    &.is--shaded {
        background-color: $lt-bg;
    }
    padding: 60px 0;
    h2 {
        font-weight: 300;
        margin-left: auto;
        margin-right: auto;
        max-width: 305px;
        text-align: center;
        > sup {
            font-size: 50%;
            top: -1em;
        }
    }
    ul {
        list-style-type: none;
        padding-left: 0;
        text-align: center;
    }
    li {
        padding-top: 26px;
    }
    i {
        color: $dark;
        font-size: em(70);
    }
    strong {
        color: $li-dkpurple;
        display: block;
        line-height: 1.4em;
        margin: 10px auto 0;
        width: 161px;
    }

    /*
    *  Variants
    */ 
    &.adv__apply-info--type-2 {
        padding-bottom: 20px;
        h2 {
            font-size: 25px;
            line-height: 1.36;
            margin-bottom: 75px;
        }
        h3 {
            color: $li-purple;
            font-size: 26px;
            font-weight: 400;
            margin: 12px 0; 
        }
        p {
            font-size: 14px;
            line-height: 1.429;
            margin: 0 auto 50px;
            max-width: 280px;
        }
    }
    
}
@mixin adv__apply-info--min-sm() {
  .adv__apply-info {
    h2 {
        margin-bottom: 40px;
        max-width: 617px;
    }
    li {
        display: inline-block;
        padding: 0 15px;
    }
    /*
    *  Variants
    */
    &.adv__apply-info--type-2 {
        h2 {
            font-size: 30px;
        }
        h3 {
            font-size: 18px;
        }
        p {
            padding: 0 18px;
        }
    }
  }
}
@mixin adv__apply-info--min-md() {
    .adv__apply-info {
        /*
        *  Variants
        */
        &.adv__apply-info--type-2 {
            h2 {
                font-size: 34px;
                max-width: 780px;
            }
            h3 {
                font-size: 26px;
                margin-top: 40px;
            }
            p {
                font-size: 16px;
                line-height: 1.625;
                padding: 0;
            }
        }
    }
}