.shortTextCta {
    &.default {
        margin: 50px auto 60px;
        text-align: center;

        .shortTextCta__content {
            padding: 0 20px;
        }

        .description {
            color: #3A1D59;
            font-size: 20px;
            font-weight: 300;
            line-height: 1.6;
        }

        .title {
            color: #231033;
            font-size: 24px;
            font-weight: normal;
            line-height: 1.25;
            margin: 1.5em 0 0.8em;
        }

        .btn {
            font-size: 16px;
            font-weight: bold;

            a,
            a:hover {
                color: white;
                text-decoration: none;
            }
        }
    }
}

@mixin shortTextCta--min-sm() {
    .shortTextCta {
        &.default {
            .shortTextCta__content {
                padding: 0 85px;
            }

            .description {
                font-size: 34px;
                line-height: 1.25;
            }

            .title {
                font-size: 24px;
            }

            .btn {
                background: none;
                border: none;
                color: #1F9CE8;
                font-size: 34px;
                padding: 0;

                a {
                    color: inherit;

                    &:hover {
                        color: #1170a9;
                    }
                }
            }
        }
    }
}

@mixin shortTextCta--min-md() {
    .shortTextCta {
        &.default {
            .shortTextCta__content {
                padding: 0 85px;
            }
        }
    }
}