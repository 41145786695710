.adv__testimonials {
  margin: 0 auto;
  padding-bottom: 0;
  padding-top: 50px;
  position: relative;
  width: 244px;
  @include breakpoint($sm) {
    width: auto;
  }
  .testimonials-scroll-outer {
    height: 335px;
    overflow: hidden;
    transition: 0.3s;
    @include breakpoint($sm) {
      /* using important here to override height set with javascript when displayed as a slider */
      height: auto !important; /* stylelint-disable-line declaration-no-important */
      overflow: visible;
      transition: none;
    }
  }
  .testimonials-scroll-inner {
    width: 800px;
    @include breakpoint($sm) {
      width: auto;
    }
  }
  .testimonial-col {
    float: left;
    left: 0;
    opacity: 0;
    position: relative;
    transition: left 0.3s, opacity 0.1s;
    @include breakpoint($sm) {
      /* using important here to override left positioning set with javascript when displayed as a slider */
      left: 0 !important; /* stylelint-disable-line declaration-no-important */
      opacity: 1;
    }
    &.active {
      opacity: 1;
    }
  }
  .carousel-control {
    background-color: transparent;
    border: none;
    box-shadow: none;
    height: 60px;
    outline: none;
    position: absolute;
    right: -10px;
    top: 75px;
    width: 20px;
    z-index: $z-index-top - 10;
    &:focus,
    &:active {
      border: none;
      box-shadow: none;
      outline: none;
    }
    &[disabled] {
      opacity: 0.25;
    }
    &:after {
      color: #000000;
      content: "\e91b";
      display: block;
      font-family: li-org;
      font-size: 2.75em;
      left: 0;
      position: relative;
    }
    &[data-direction="prev"] {
      left: -10px;
      right: auto;
      &:after {
        @include rotate(180deg);
      }
    }
    @include breakpoint($sm) {
      display: none;
    }
  }
  .carousel-indicators {
    list-style: none;
    margin-top: 20px;
    padding-left: 0;
    text-align: center;
    li {
      background-color: #9b9b9b;
      border-radius: 11px;
      display: inline-block;
      height: 9px;
      margin: 0 5px;
      width: 9px;
      &.active {
        background-color: $li-dkpurple;
      }
    }
    @include breakpoint($sm) {
      display: none;
    }
  }
  img {
    @include border-radius(50%);
    display: block;
    margin: 0 auto 20px auto;
    width: 118px;
  }
  p {
    margin-left: auto;
    margin-right: auto;
    max-width: 214px;
    &.byline > strong:first-of-type {
      display: block;
    }
  }
}