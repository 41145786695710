.adv__header-affix {
  min-height: 63px;
}
.adv__header {
    box-shadow: 1px 0 1px rgba(0, 0, 0, 0.2);
    padding-bottom: 16px;
    padding-top: 16px;
    position: relative;

    .brand {
        float: left;
        margin-left: -6px;
        margin-top: 1px;
        width: 140px;
    }

    .tel {
        float: right;
        font-size: em(21);
        font-weight: 700;
        line-height: 2.2;
    }

    &.affix {
        background-color: $light;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        z-index: $z-index-top;
    }

    .header-cta {
        font-size: 14px;
        font-weight: 600;
        height: 100%;
        min-height: 63px;
        padding: 22px 15px;
        position: absolute;
        right: 0;
        top: 0;
    }
}
@mixin adv__header--min-sm() {
  .adv__header-affix {
    min-height: 70px;
  }
  .adv__header {
    padding-bottom: 12px;
    padding-top: 12px;
    .brand {
      margin: 0;
      width: 220px;
    }

    & .is--cellphone{
        a:hover {
            color: $li-blue;
        }
    }
  }
}
@mixin adv__header--min-xg() {
    .adv__header {
        .brand {
            margin-left: 25px;
        }

        .tel {
            margin-right: 25px;

            & .is--cellphone{
                a:hover {
                    color: $li-blue;
                }
            }
        }
    }
}