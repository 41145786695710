.article .sitemap {
    list-style-type: none;
    padding-left: 0;
    ul { // all ul
        padding-left: 0;
    }
    & > li { // top-level li
        padding-left: 0;
        & > a {
            display: block;
            font-size: 1.5em;
            font-weight: 600;
            margin-bottom: 15px;
        }
        & > ul {
            list-style-type: none;
            & > li { // 2nd level li
                padding-left: 10px;
                & > a {
                    font-size: 1.2em;
                    font-weight: 600;
                }
                & > ul  {
                    border-top: 1px solid $border-line;
                    list-style-type: none;
                    padding-top: 15px;
                    & > li { // 3rd level li
                        padding-left: 10px;
                    }
                }
            }
        }
    }
}
@mixin sitemap--min-sm() {
    .sitemap {
        & > li { // top-level li
            & > ul {
                & > li { // 2nd level li
                    & > ul {
                        -webkit-column-count: 2;
                        -moz-column-count: 2;
                        column-count: 2;
                        -webkit-column-gap: 30px;
                        -moz-column-gap: 30px;
                        column-gap: 30px;
                    }
                }
            }
        }
    }
}
@mixin sitemap--min-md() {
    .sitemap {
        & > li { // top-level li
            & > ul {
                & > li { // 2nd level li
                    & > ul {
                        -webkit-column-count: 3;
                        -moz-column-count: 3;
                        column-count: 3;
                        -webkit-column-gap: 40px;
                        -moz-column-gap: 40px;
                        column-gap: 40px;
                    }
                }
            }
        }
    }
}
