.policy-info {
    padding: 50px 0;

    .description,
    .info-list {
        margin: 0 auto;
        max-width: 290px;
        text-align: center;
    }

    .description {

        p {
            color: $li-mdpurple;
            font-size: 16px;
            line-height: 25px;
        }

        a {
            font-size: 14px;
            font-weight: bold;
            margin-top: 10px;
            padding: 10px 12px;
        }

        .final-expense & {
            a {
                padding: 12px 29px;
            }
        }
    }

    .info-list {
        list-style: none;
        padding: 0;

        li {
            margin-top: 50px;
        }

        i {
            color: $dark;
            font-size: 82px;

            &.icon-heart-usa,
            &.icon-choices {
                font-size: 72px;
            }
        }

        h2 {
            font-size: 24px;
            line-height: 33px;
            margin-top: 15px;
        }

        p {
            font-size: 14px;
            line-height: 21px;
        }

        .padding-adjustment {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

@mixin policy-info--min-sm() {
    .policy-info {

        .description {
            max-width: 590px;

            p {
                font-size: 18px;
                line-height: 28px;
            }

            a {
                font-size: 16px;
                margin-top: 10px;
                padding: 10px 12px;
                width: 156px;

                .final-expense & {
                    padding: 10px 29px;
                    width: 172px;
                }
            }
        }

        .info-list {
            max-width: 100%;

            li {
                margin-top: 50px;
            }

            i {
                font-size: 64px;

                &.icon-heart-usa,
                &.icon-choices {
                    font-size: 64px;
                }
            }

            h2 {
                font-size: 18px;
                line-height: 24px;
                margin-top: 15px;

                .final-expense & {
                    min-height: 48px;
                }
            }

            p {
                font-size: 14px;
                line-height: 21px;
            }

            .padding-adjustment {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}

@mixin policy-info--min-md() {
    .policy-info {

        .description,
        .info-list {
            text-align: left;
        }

        .description {
            padding-top: 80px;

            .final-expense & {
                padding-top: 0;
            }

            p {
                font-size: 26px;
                line-height: 37px;
            }

            a {
                margin-top: 15px;
            }
        }

        .info-list {

            .icon-wrap,
            .text-wrap {
                display: inline-block;
                vertical-align: top;
            }

            .icon-wrap {
                width: 90px;
            }

            .text-wrap {
                width: 284px;
            }

            li:first-of-type {
                margin-top: 0;
            }

            .wrap {
                display: inline-block;
                float: right;
                vertical-align: top;
            }

            h2 {
                font-size: 22px;
                line-height: 30px;
                margin-top: 0;

                .final-expense & {
                    min-height: unset;
                }
            }

            p {
                font-size: 16px;
                line-height: 26px;
                padding-right: 10px;
            }

            .padding-adjustment {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}
