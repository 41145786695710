.site-footer {
  background-color: $footer-bg;
  color: $footer-legal;
  font-size: em(14);
  font-weight: 300;
  padding: 24px 0 48px;

  .logo {
    height: 30px;
  }

  .social {
    float: right;
    max-width: 88px;

    li {
      margin-bottom: 13px;
      padding: 0;

      &:nth-of-type(odd) {
        padding-right: 13px;
      }
    }

    a {
      color: $light;
      font-size: em(36);

      &:hover {
        text-decoration: none;
      }

      &:focus {
        outline: 1px dotted $li-ltgray;
        text-decoration: none;
      }
    }
  }

  .legal {
    p:nth-of-type(1) {
      clear: both;
    }

    p:nth-of-type(2) {
      clear: both;
    }

    a {
      color: inherit;
      text-decoration: underline;
    }
  }

  nav {
    margin: 20px 0;

    ul {
      list-style: none;
      padding-left: 0;
    }

    a {
      color: $footer-nav;
      line-height: 32px;
    }
  }

  &.branded-site-footer {
    padding: 40px 0;

    & > .container {
      padding: 0 35px;
    }

    .logos {
      font-size: 22px;

      .icon {
        color: $light;
        padding-right: 15px;
      }
    }

    .legal {
      color: $footer-text-color;
      font-size: 14px;
      line-height: 19px;

      a {
        color: inherit;
        text-decoration: underline;
      }

      .footer__logos {
        float: right;
      }
    }
  }

  .security-logos {
    float: left;
    margin: 0 0 15px;

    .bbb {
      display: block;
      margin-bottom: 14px;
    }
  }

  &.choice-site-footer {
    .legal p.hidden-xs {
      display: none;
    }
  }
}

@mixin site-footer--min-sm() {
  .site-footer {
    .logo {
      height: 48px;
      margin-left: -6px;
    }

    .social {
      li {
        margin-bottom: 0;
        padding: 0 10px 0 0;

        &:nth-of-type(odd) {
          padding-right: 10px;
        }
      }
    }

    .legal {
      p:nth-of-type(1) {
        clear: none;
      }

      p:nth-of-type(2) {
        clear: none;
      }
    }

    &.branded-site-footer {
      padding: 30px 0;

      & > .container {
        padding: 0 20px;
      }
    }

    .security-logos {
      float: right;
      margin: 0 0 25px;
      max-width: 300px;
      min-width:300px;
      text-align: right;

      .bbb {
        float: right;
        margin-bottom: 9px;
        margin-top: 0;
      }
    }
  }
}
@mixin site-footer--min-md() {
  .site-footer {
    nav {
      margin: 30px 0 40px;

      ul {
        li {
          border-right: 1px solid $footer-nav;
          display: inline-block;
          line-height: 1em;
          margin-bottom: 10px;
          margin-right: 14px;
          padding-right: 16px;

          &:last-of-type {
            border-right: none;
            margin-right: 0;
            padding-right: 0;
          }
        }
      }

      &:not(.no-line-br) {
        ul {
          float: none;
          max-width: none;

          li {
            border-right: none;
            display: inline;
            margin-right: 12px;
            padding-bottom: 10px;
            padding-right: 0;

            &:after {
              content: "|";
              display: inline-block;
              margin-bottom: 6px;
              padding-left: 14px;
            }

            &:last-child {
              margin-right: 0;

              &:after {
                content: none;
              }
            }
          }
        }
      }

      a {
        line-height: inherit;
      }
    }

    &.branded-site-footer {
      padding: 40px 0;

      & > .container {
        padding: 0 15px;
      }
    }

    .security-logos {
      margin: 0 0 25px 35px;
      max-width: none;

      .bbb {
        float: left;
        margin-right: 22px;
      }
    }
  }
}
