.supplemental {
  padding-top: 0;

  h2 {
    font-size: em(24);
    line-height: 1.4em;
  }
}

@mixin advertorial--min-sm() { 
  .supplemental {
    h2 {
      font-size: em(34);
      font-weight: 300;
    }
  }
}