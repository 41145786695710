.state-select {
    padding-top: 30px;
    text-align: center;

  label {
    color: $li-purple;
    display: block;
    font-size: em(24);
    font-weight: 400;
    line-height: 1.3em;
    margin-bottom: 16px;
    text-align: center;
  }

  .map {
    margin: 50px 0;
  }

  #us-map {
    path {
      fill: $li-blue;
      stroke: $light;
      stroke-width: 1;
      transition: all 0.2s;
    }

    a:hover path {
      fill: $light;
      fill: $li-pink;
    }

    text {
      display: none;
      fill: $li-blue;
      text-decoration: none;
    }

    line {
      display: none;
      stroke: $li-blue;
      stroke-width: 1;
    }

    a:hover text {
      fill: $li-pink;
    }
    a {
      cursor: pointer;
      font-size: 11px;
    }
  }
}
@mixin state-select--min-sm() {
  .state-select {
    label {
      font-weight: 300;
    }
  }
}