.choice__sidebar {
  @include breakpoint($sm) {
    margin: 36px auto 0;
    width: 641px;
  }

  @include breakpoint($xg) {
    margin: 0;
    position: absolute;
    right: 0;
    top: -454px;
    width: 445px;
    z-index: 2;
  }

  &-header {
    background-color: $light;
    border-bottom: none;
    color: $li-mdgray;
    padding: 41px 32px 27px;
    position: relative;
    text-align: center;

    @include breakpoint($sm) {
      border: {
        color: $dark;
        style: solid;
        width: 1px 1px 0px 1px;
      }

      padding: 40px 82px 12px;
    }

    @include breakpoint($xg) {
      padding: 25px 55px 0;
    }

    h2 {
      color: $li-blue;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      margin-bottom: 0;

      @include breakpoint($sm) {
        font-size: 30px;
        line-height: 40px;
      }
    }

    .tfn {
      background-color: $li-blue;
      color: $light;
      display: block;
      font-size: em(16);
      font-weight: bold;
      padding: 10px 0;

      &::before {
        content: "Call ";
      }
      
      &::after {
        content: "\e900";
        display: inline-block;
        font-family: li-org;
        font-size: 1em;
        margin-left: 5px;
        position: relative;
      }

      @include breakpoint($sm) {
        background-color: transparent;
        color: $li-blue;
        font-size: em(28);
        padding: 0;

        &::before,
        &::after {
          content: none;
        }
      }

      @include breakpoint($xg) {
        font-size: em(34);
      }
    }

    &::after {
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 30' fill='white' preserveAspectRatio='none'%3E%3Cpolygon points='0,0 100,15 200,0' /%3E%3C/svg%3E");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      border-top: 2px solid $light;
      bottom: -98px;
      content: "";
      height: 100px;
      left: 0;
      position: absolute;
      width: 100%;

      @include breakpoint($xg) {
        bottom: -88px;
        height: 90px;
      }
    }
  }

  &-form {
    background-color: $li-purple;
    color: $light;
    padding: 100px 32px 39px;

    @include breakpoint($sm) {
      padding: 100px 50px 39px;
    }

    @include breakpoint($xg) {
      padding-top: 90px
    }

    @include honeypot-field;

    a {
      color: $light;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 25px;

      @include breakpoint($sm) {
        padding: 0 34px;
        text-align: center;
      }

      @include breakpoint($xg) {
        padding: 0;
        text-align: left;
      }
    }

    h3 {
      color: $light;
      font-size: 20px;
      font-weight: 400;
      line-height: 25px;
      margin-bottom: 12px;
      margin-top: 24px;

      @include breakpoint($sm) {
        line-height: 30px;
        margin-bottom: 50px;
        padding: 0 34px;
        text-align: center;
      }

      @include breakpoint($xg) {
        line-height: 25px;
        margin-right: 130px;
        padding: 0;
        text-align: left;
      }
    }

    label {
      font-size: 16px;
      font-weight: 700;
      line-height: 16px;
      margin-bottom: 10px;
    }

    .tel {
      font-weight: bold;
    }

    .form-group {
      margin-bottom: 24px;

      @include breakpoint($sm) {
        display: inline-block;
        margin: 0 12px 25px;
        width: 256px;

        &:nth-child(even) {
          margin-right: 0; 
        }

        &:nth-child(odd) {
          margin-left: 0;
        }

        &:last-of-type {
          margin: 0;
          text-align: center;
          width: 100%;
        }
      }

      @include breakpoint($xg) {
        display: block;
        margin: 0 0 20px;
        width: 100%;

        &:last-of-type {
          text-align: left;
        }
      }
    }

    .form-control {
      border: none;
      border-radius: 0;
      color: $form-input;
      font-weight: 600;
      height: 44px;
      padding: 6px 24px;
      transition: box-shadow 0.3s linear 0s;
    }

    button[type="submit"] {
      background-color: $li-blue;
      border-color: $li-blue;
      font-size: em(16);
      font-weight: bold;
      margin-top: 8px;
      padding: 4px 18px 10px;
      width: auto;

      @include breakpoint($sm) {
        margin: 25px auto 0;
      }

      @include breakpoint($xg) {
        margin-top: 0;
      }

      span::after {
        font-size: 20px;
        font-weight: 100;
        margin-left: 6px;
        top: 3px;
        transition: transform 0.25s linear 0s;
      }

      &:hover {
        span::after {
          transform: translateX(5px);
        }
      }

      & + p {
        display: none;

        .tfn {
          color: $light;
          font-weight: bold;
        }
      }
    }

    .form-disclaimer {
      font-size: em(10);
      margin: 0;

      @include breakpoint($sm) {
        font-size: em(12);
      }

      a {
        color: $light;
        text-decoration: underline;
      }
    }
  }

  .btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus {
    outline: none;
  }
}
