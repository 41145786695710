.blog-list {
    article {
        padding: 40px 0 30px;
        position: relative;

        &:after {
            background: rgba($li-ltgray,.1);
            bottom: 0;
            content: '';
            height: 1px;
            left: 0;
            position: absolute;
            width: 320px;

            @media screen and (max-width: $screen-xs-max) {
                width: 100%;
            }
        }
        &:first-of-type {
            padding-top: 0;
        }
        &:last-of-type:after {
            content: none;
        }
    }

    h2 {
        margin-bottom: 50px;
    }
    h3 {
        font-size: 18px;
        a {
            color: $purple;
            font-size: 24px;
            font-weight: 600;
            text-decoration: none;
        }
    }
    p {
        color: $text-primary;
        margin: 0;
    }
    .btn-sm {
        color: $li-blue;
        padding: 1px 15px;
        text-decoration: none;
        span {
            display: block;
            margin-top: 3px;
            &:after {
                content: none;
            }
        }
    }
    .item__description {
        margin-bottom: 20px;
    }
}

@mixin blog-list--min-sm() {
    .blog-list {
        h2 {
            margin-top: 20px;
        }
    }
}