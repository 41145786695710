.adv__providers {
  background-color: $row-bg;
  padding-bottom: 40px;
  padding-top: 50px;
  h2 {
    text-align: center;
  }
  ul {
    font-size: em(50);
    margin-top: 30px;
    text-align: center;
    li {
      display: block;
      [class^="icon-"], [class*=" icon-"] {
          &:before {
            color: $logos-dgray;
          }
      }
    }
  }
}
@mixin adv__providers--min-sm() {
  .adv__providers {
    h2 {
      margin-left: auto;
      margin-right: auto;
      max-width: 620px;
    }
    ul {
      li {
        display: inline-block;
        &+li {
          margin-left: 50px;
        }
      }
    }
  }
}