.hero {
    margin-bottom: 36px;
    position: relative;

    .hero__bg, &.hero-default, &.hero-forms {
        background-position: 60%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 156px;

        &.typ-bg {
            background-position: 40%;
            height: 135px;
        }
    }

    &.hero-default {
        background-image: url("/assets/images/hero/overview.jpg");
    }

    &.hero-forms {
        background-image: url("/assets/images/hero/forms.jpg");
    }

    .hero__cta {
        background: $li-purple;
        color: $light;
        padding: 40px 0;

        h1 {
            color: $light;
            font: 300 24px/30px $font-family-sans-serif;
        }

        p {
            font: 300 14px/19px $font-family-sans-serif;
            margin-bottom: 0;

            strong {
                font-weight: 600;
            }

            a {
                color: $li-blue;
                text-decoration: none;
                white-space: nowrap;
            }
        }

        .cta__form {
            margin: 0 auto;
            max-width: 290px;

            form {
                margin: 25px 0 20px;
                position: relative;

                input {
                    border: none;
                    color: $form-input;
                    display: inline-block;
                    font-size: 16px;
                    height: 56px;
                    max-width: 180px;
                    padding-left: 15px;
                    width: 100%;
                    @include placeholder-label-zipcode();
                }

                .btn {
                    background: $li-blue;
                    border-color: $li-blue;
                    font-size: 16px;
                    height: 56px;
                    margin-left: -4px;
                    position: absolute;
                    right: 0;
                    top: -1px;
                    vertical-align: top;
                    width: 104px;

                    &:hover,
                    &:focus,
                    &:active {
                        background-color: darken($li-blue, 10%);
                        border-color: darken($li-blue, 10%);
                        color: $light;
                    }

                    span:after {
                        font-size: 1.15em;
                    }
                }
            }
        }
    }

    &.trustbar-cta {
        min-height: 70px;
        padding-top: 70px;

        .hero__bg {
            background-position: center;

            @include breakpoint($sm) {
                height: 223px;
            }
        }

        .hero__cta {
            height: auto;
            padding: 15px 5px;
            position: fixed;
            top: 42px;
            width: 100%;
            z-index: 777;

            @include breakpoint($sm) {
                padding: 11px 10px;
                top: 90px;
            }

            @include breakpoint($md) {
                padding: 15px;
            }

            h1 {
                display: none;
                margin: 0;

                @include breakpoint($sm) {
                    display: inline-block;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    margin-right: 20px;
                    max-width: 335px;
                    text-align: left;
                    vertical-align: middle;
                    width: 100%;
                }

                @include breakpoint($md) {
                    margin-right: 30px;
                    max-width: 460px;
                }
            }

            .cta__form {
                margin: 0;
                max-width: 100%;
                width: 100%;

                @include breakpoint($sm) {
                    text-align: center;
                }

                form {
                    margin: 0 auto;
                    max-width: 325px;
                    width: 100%;

                    @include breakpoint($sm) {
                        display: inline-block;
                        vertical-align: middle;
                    }

                    input {
                        height: 40px;
                        max-width: 212px;
                        width: 100%;

                        & + label {
                            left: 50px;
                            top: 9px;
                        }

                        &:focus + label, &:valid + label, &.not-empty + label {
                            display: none;
                        }
                    }

                    .btn {
                        height: 40px;
                        line-height: 0;
                        margin: 0;
                        max-width: 102px;
                        top: 0;
                        width: 100%;

                        @include breakpoint($sm) {
                            margin-left: 6px;
                            position: static;
                        }

                        span:after {
                            margin-left: 1px;
                            top: 2px;
                        }
                    }
                }
            }

            .input-validation-error {
                background: $form-error-bg;
            }

            .field-validation-error {
                background: $li-ltgray-alt-2;
                padding: 2px 6px;
                position: absolute;
                right: 24px;
                top: -12px;
                z-index: 777;

                &:after {
                    border-right: 12px solid transparent;
                    border-top: 10px solid $li-ltgray-alt-2;
                    bottom: -8px;
                    content: "";
                    height: 0;
                    left: 0;
                    position: absolute;
                    width: 0;
                }

                span {
                    color: $light;
                    font-size: 13px;
                }
            }
        }
    }
}
@mixin hero--min-sm() {
    .hero {
        margin-bottom: 54px;

        .hero__bg, &.hero-default, &.hero-forms {
            height: 223px;
            &.typ-bg {
                background-position: 20%;
                height: 223px;
            }
        }

        &--cta {
            height: auto;

            .hero__bg {
                height: 295px;
            }

            .hero__cta {
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
                width: 55%;

                p {
                    max-width: 280px;
                }

                .cta__form {
                    margin-left: 60px;
                    margin-right: 0;
                    max-width: 440px;

                    form {
                        input {
                            max-width: 173px;
                        }

                        .btn {
                            margin-left: 3px;
                            position: static;
                            right: auto;
                            top: auto;
                        }
                    }
                }
            }
        }
    }
}

@mixin hero--min-md() {
    .hero {

        .hero__bg.typ-bg {
            background-position: 40%;
        }

        &--cta {
            height: auto;

            .hero__bg {
                height: 313px;
            }

            .hero__cta {
                padding: 50px 0;

                h1 {
                    font-size: 34px;
                    line-height: 40px;
                }

                p {
                    max-width: 100%;
                }


                .cta__form {

                    form {
                        input {
                            max-width: 212px;
                        }

                        .btn {
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
    }
}