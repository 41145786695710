html {
  height: 100%;
}
body {
  font-weight: 300;
  height: 100%;
  line-height: 1.6;
  padding-top: $navbar-height;
  &.nav-hidden {
	  padding-top: 0;
  }
}
b, strong {
  font-weight: 600;
}
.skip-navigation {
  height: 0;
  opacity: 0.9  ;
  transition: height 0.1s;
  &:focus {
	background: $blue-bg;
	clip: initial;
	color: $light;
	font-size: em(12);
	height: 30px;
	left: 0;
	margin: 0;
	outline: none;
	padding: 6px 10px;
	text-decoration: none;
	top: 0;
	width: auto;
	z-index: $z-index-top;
  }
}
select {
	-webkit-appearance: none;
	-moz-appearance: none;
	background-image: linear-gradient(45deg, transparent 50%, $border-line 50%), linear-gradient(135deg, $border-line 50%, transparent 50%), linear-gradient(to right, #fff, #fff);
	background-position: calc(100% - 23px) calc(1.2em + 2px), calc(100% - 14px) calc(1.2em + 2px), calc(100% - 2.5em) 0.5em;
	background-repeat: no-repeat;
	background-size: 8px 9px, 9px 7px, 1px 0;
	border: 1px solid $border-line;
	-webkit-border-radius: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	color: $select-text;
	font-size: 12px;
	min-width: 290px;
	padding: 9px 15px;
}
main::before {
  content: "";
  display: block;
  height: $navbar-height; /* fixed header height*/
  margin: -$navbar-height 0 0; /* negative fixed header height */
}
// general typography
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
}
// buttons
.btn { // base class
  font-size: 12px;
  transition: background-color 0.2s ease;
  @include add-chevron();
}
.btn-primary {
  border: 1px solid $btn-primary-bg;
}
.btn-action {
  background-color: $btn-action-bg;
  border: 1px solid $btn-action-bg;
  color: $light;
  &:hover, 
  &:focus, 
  &:active {
	background-color: darken($btn-action-bg, 10%);
	border-color: darken($btn-action-bg, 10%); 
	color: $light;
  } 
}
.btn-back {
  background-color: transparent;
  border: 1px solid transparent;
  @include add-chevron("before");
  &:hover,
  &:focus,
  &:active {
	color: inherit;
	text-decoration: underline;
  }
}
.btn-sm {
  font-weight: 600;
  height: 28px;
  padding: 1px 15px;
  &>span:after {
	font-size: 1.25em;
	top: 2px;
  }
  &:hover,
  &:focus,
  &:active {
	border-color: transparent;
  }
}
input, textarea {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
// remove spinners from number inputs
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
  margin: 0;
}
.input-validation-error {
	border: 1px solid $form-error !important; //always override border style for error state
	outline: none;
}
// base table style 
table {
  color: $li-mdpurple;
  font-size: 11px;
  margin-left: -15px;
  margin-right: -15px;
  caption {
	color: $li-mdpurple;
	font-size: 24px;
	font-weight: 400;
	margin-bottom: 10px;
	margin-top: 40px;
  }
  th {
	padding: 15px 0;
	text-align: left;
  }
  td {
	border-top: 1px solid $table-border;
	font-weight: 400;
	padding: 15px 30px 15px 0;
	vertical-align: top;
	&:first-child {
	  padding-left: 15px;
	}
  }
}

.affix {
	position: static;
}

a[href^="tel:"] {
	white-space: nowrap;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

[v-cloak] {
  display: none;
}
.invisible {
  visibility: hidden;
}
/// typeform modal (use important to override typeform settings)
.css-belpyc {
  z-index: 2000 !important;
}
.css-z9fapl {
  padding: 0 30px;
  @include breakpoint(600px) {
	font-size: 40px !important;
  }
}
// base validation styles
.field-validation-error,
.field-validation-error span {
  color: red;
  display: block;
  font-size: 12px;
}
.scroll-message-mobile {
	display: none;
	font-style: italic;
	font-weight: 600;
	margin: 0;
	text-align: center;
	@media screen and (max-width: $screen-xs-max) {
		display: block;
	}
}
// limited implementation of bootstrap's table-reponsive
.table-responsive {
  margin-left: -15px;
  margin-right: -15px;
  min-height: 0.01%; // Workaround for IE9 bug (see https://github.com/twbs/bootstrap/issues/14837)
  overflow-x: auto;
  > table {
	margin-left: 0;
	margin-right: 0;
  }
  @media screen and (max-width: $screen-xs-max) {
	-ms-overflow-style: -ms-autohiding-scrollbar;
	overflow-y: hidden;
	// Tighten up spacing
	> table {
	  margin-bottom: 0;
	  // Ensure header content doesn't wrap
	  > thead {
		> tr {
		  > th {
			white-space: nowrap;
		  }
		}
	  }
	}
  }
}
@mixin base--min-sm() {
	body {
		padding-top: 88px;
	}

	main::before {
		height: 90px;
		margin: -90px 0 0;
	}

	table {
		font-size: 14px;

		caption {
			font-size: 28px;
			font-weight: 300;
			padding-left: 15px;
			text-align: left;
		}
	}
}
@mixin base--min-md() {
	.sticky-sidebar {
		&.affix {
			max-width: 294px;
			position: fixed;
			top: 100px;
		}

		&.affix-top {
			max-width: 294px;
			position: static;
		}

		&.affix-bottom {
			max-width: 294px;
			position: absolute;
		}
	}
}
@mixin base--min-lg() {
  .is--cellphone {
	display: inline-block;
	position: relative;
	z-index: 0;
  }
}
