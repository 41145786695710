.adv__text-content {
  padding: 50px 0;
  section {
    margin-top: 50px;
  }
  h2 {
    font-weight: 100;
    margin-bottom: 16px;
    max-width: 735px;
  }
  p {
    margin-bottom: 20px;
    
  }
  a {
    color: $li-purple;
    font-weight: 500;
  }
}

@mixin adv__text-content--min-md() {
    .adv__text-content {
        h2 {
            line-height: 46px;
            margin-bottom: 24px;
        }
        p {
            margin-bottom: 40px;
        }
    }
}