.widget__cta {
    .form {
        background: $li-purple;
        color: $light;
        margin: 60px auto;
        max-width: 294px;
        text-align: center;

        img {
            margin-top: 30px;
            width: 160px;
        }

        .form__top {
            p {
                font-size: 18px;
                font-weight: 300;
                line-height: 25px;
                margin: 30px auto 20px;
                max-width: 240px;
            }
        }

        form {
            margin: 0 auto;
            position: relative;
            width: 160px;

            input {
                @include placeholder-label-zipcode("small");
                border: none;
                color: $form-input;
                font-size: 16px;
                height: 44px;
                max-width: 100%;
                padding: 0 15px;
                width: 100%;
            }

            .btn {
                background: $li-blue;
                border-color: $li-blue;
                display: block;
                font-size: 14px;
                height: 44px;
                margin-top: 10px;
                width: 100%;
            }
        }

        .form__bottom {
            background: $li-ltpurple;
            margin-top: 30px;
            padding: 40px 30px;

            p {
                font-size: 16px;
                font-weight: 300;
                line-height: 23px;
            }

            .is--cellphone {
                a {
                    color: $light;
                    font: 700 28px/23px $font-family-sans-serif;
                    text-decoration: none;
                }
            }
        }
    }
    &.widget__cta--organic-carrier {
        .form {
            img {
                width: 220px;
            }
            .form__bottom {
                background: $li-purple;
                margin-top: 0;
                padding-top: 30px;
            }
        }
    }
}
.widget__cta--mobile-top {
    margin: -50px 0 -30px;
}

@mixin widget-cta--min-sm() {
    .widget__cta {
        .form {
            max-width: 100%;

            img {
                width: 180px;
            }

            form {
                .input-validation-error {
                    margin-bottom: 30px;
                }

                .btn {
                    margin-top: 20px;
                }
            }

            .title {
                margin-top: 5px;
                max-width: 100%;
            }

            .form__bottom {
                padding: 30px;
            }
            .field-validation-error {
                position: absolute;
                text-align: center;
                top: 44px;
                width: 100%;
            }
        }
    }
}

@mixin widget-cta--min-md() {
    .widget__cta {
        .form {
            margin: 0;
            max-width: 294px;

            img {
                width: 160px;
            }

            .title {
                margin-top: 25px;
            }
        }
    }
}