﻿.trust-banner {
	padding: 62px 50px;

	@include breakpoint($sm) {
		padding: 60px 0 70px 0;
	}

	p {
		font-size: 24px;
		font-weight: 500;
		line-height: 34px;
		text-align: center;

		@include breakpoint($sm) {
			margin: 0 auto;
			max-width: 577px;
		}

		@include breakpoint($lg) {
			font-size: 34px;
			font-weight: 300;
			line-height: 44px;
			max-width: 817px;
		}
	}
}