
.pctBlurb {
    $maxInputWidth: 80%;
    $labelVerticalSpacing: 20px; // used for label/placeholder spacing
    margin: 60px auto;
    text-align: center;

    h2 {
        font-size: 20px; //12px;
        font-weight: 300;
        line-height: 1.4;
        margin: 20px 0;
    }

    .stat {
        $size: 122px;
        height: $size;
        margin: 0 auto;
        width: $size;

        &-disc {
            stroke-width: 0.7px;

            &Bg {
                stroke: #d8d8d8;
            }

            &Fg {
                stroke: #239ee8;
                transform: scaleX(-1);
            }
        }

        &-text {
            color: #3A1D59;
        }

        &-value {
            font-size: 36px;
            font-weight: 600;
        }

        &.animate {
            .stat-discFg {
                animation-duration: 2.2s;
            }
        }
    }

    .form-title {
        color: #231033;
        font-size: 24px;
        font-weight: normal;
        margin: 1em 0 0.4em;
    }

    form {
        position: relative;
    }

    input, .btn-action {
        height: 44px;
        margin-top: $labelVerticalSpacing;
        vertical-align: top;
        width: $maxInputWidth;
    }

    input {
        border: 1px solid #9f9f9f;
        padding: 4px 12px;
        @include placeholder-label-zipcode();

        & + label {
            font-weight: normal;
            left: 25%;
            right: 25%;
            text-align: center;
            top: 30px;
        }

        &:focus + label {
            left: (100 - $maxInputWidth) / 2;
            right: auto;
            top: -6px;
            width: auto;
        }
    }

    .btn-action {
        background-color: #1F9CE8;
        border: none;
        font-size: 16px;
        margin: $labelVerticalSpacing auto 8px;
        padding: 6px 18px;
    }

    .trow {
        margin: 0 -15px;
    }

    .ccol {
        display: block;
        padding: 0 15px;
    }
}

@mixin pctBlurb--min-sm() {
    .pctBlurb {
        $inputWidth: 176px;

        h2 {
            font-size: 28px;
            margin: 0;
        }

        .stat {
            margin: 0 0 0 auto;
        }

        input, .btn-action {
            width: auto;
        }

        input {
            min-width: $inputWidth;

            & + label {
                left: 25%;
                right: auto;
            }

            &:focus + label {
                left: 18%;
            }
        }

        .btn-action {
            margin-left: 23px;
            min-width: $inputWidth; //176px;
        }

        .trow {
            display: table;
            text-align: left;
            width: 100%;
        }

        .ccol {
            display: table-cell;
            vertical-align: middle;

            &:first-child {
                width: 25%;
            }
        }
        .field-validation-error {
            display: block;
            margin: 0 auto;
            text-align: left;
            width: 460px;
        }
    }
}

@mixin pctBlurb--min-md() {
    .pctBlurb {
        margin: 114px auto 134px;

        h2 {
            font-size: 34px;
        }

        .stat {
            $size: 171px;
            height: $size;
            width: $size;

            &-value {
                font-size: 58px;
            }
        }

        input {
            & + label {
                left: 31%;
            }

            &:focus + label {
                left: 26%;
            }
        }
    }
}


@mixin pctBlurb--min-xg() {
    .pctBlurb {
        input {
            & + label {
                //left: 31%;
            }

            &:focus + label {
                left: 30%;
            }
        }
    }
}