.adv__no-cta {
    color: $light;
    margin-bottom: 70px;
    margin-top: -70px;
    text-align: center;


    .color-overlay {
        background-color: $li-purple;
        margin: 0 auto;
        max-width: 85%;
        min-height: 427px;
        position: relative;

        .final-expense & {
            background-image: url("/assets/images/backgrounds/jh-final-expense-xs.jpg");
            background-position: 50%;
            background-size: cover;
            min-height: 658px;

            &:after {
                background-color: $li-purple;
                content: '';
                height: 100%;
                left: 0;
                opacity: 0.15;
                position: absolute;
                top: 0;
            }
        }
    }

    .main_content {
        left: 50%;
        margin-bottom: 0;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        z-index: $z-index-top - 35;

        p {
            font-size: 25px;
            line-height: 34px;
            margin-left: auto;
            margin-right: auto;
            padding: 0 15px;

            span {
                display: block;
                font-size: 14px;
                line-height: 21px;
                padding: 20px 20px 0;
            }

            sup {
                .final-expense & {
                    font-size: 50%;
                    top: -1em;
                }
            }
        }
    }
}

@mixin adv__no-cta--min-sm() {
    .adv__no-cta {
        .color-overlay {
            background-image: url("/assets/images/backgrounds/calculator-lg.jpg"); // TODO: Get better images for each breakpoint
            background-position: 50%;
            background-size: cover;
            max-width: 90%;

            &:after {
                background-color: $li-purple;
                content: '';
                height: 100%;
                left: 0;
                opacity: 0.85;
                position: absolute;
                top: 0;
                width: 100%;
            }

            .final-expense & {
                background-image: url("/assets/images/backgrounds/jh-final-expense-sm.jpg");
                max-width: 96%;
                min-height: 427px;
            }
        }

        .main_content {
            p {
                font-size: 30px;
                line-height: 41px;
                max-width: 600px;
            }
        }
    }
}

@mixin adv__no-cta--min-md() {
    .adv__no-cta {
        margin-top: -180px;

        .color-overlay {
            max-width: 946px;
            min-height: 345px;

            .final-expense & {
                background-image: url("/assets/images/backgrounds/jh-final-expense-md.jpg");
                max-width: 946px;
                min-height: 345px;
            }
        }

        .main_content {
            p {
                font-size: 34px;
                line-height: 46px;
                max-width: 768px;

                .final-expense & {
                    padding: 0 9px;
                }
            }

            p span {
                font-size: 16px;
                line-height: 24px;
                padding: 20px 0px;
            }
        }
    }
}
