.modal {
    background: rgba(0, 0, 0, .3);
    bottom: 0;
    display: none;
    left: 0;
    margin: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99999;
    .modal-wrap {
        background: white;
        left: 0;       
        margin: 0 auto;
        max-width: 455px;
        padding: 15px;
        right: 0;
        width: 100%;
        @include center-block-vertical();
        .modal-head {
            display: block;
            text-align: right;
            .close-modal {
                display: inline-block;
                height: 16px;
                position: absolute;
                right: 15px;
                top: 15px;
                width: 24px;
                &:before, &:after {
                  background-color: #333;
                  content: ' ';
                  height: 16px;
                  left: 15px;
                  position: absolute;
                  width: 2px;
                }
                &:before {
                  transform: rotate(45deg);
                }
                &:after {
                  transform: rotate(-45deg);
                }
            }
        }
        .modal-content {
            &.thanks {
                padding: 50px 0;
                text-align: center;
                h2 {
                    color: $dark;
                    font-size: 45px;
                    line-height: 61px;
                    margin: 0;
                }
                p {
                    font-size: 21px;
                    margin-bottom: 0;
                }
            }
        }
    }
    &.modal-open {
        display: block;
    }
}