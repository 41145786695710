.site-nav {
    border: none;
    box-shadow: 1px 0 1px rgba(0, 0, 0, 0.2);
    @include border-radius(0);

    &.navbar {
        min-height: 42px;
    }

    .navbar-toggle, .navbar-brand {
        float: none;
    }

    .navbar-toggle, .cta-buttons, .navbar-brand {
        display: inline-block;
        vertical-align: top;
    }

    .cta-buttons {
        float: right;
    }

    .btn-primary {
        font-size: 13px;
        height: 42px;
        line-height: 20px;
        padding: 10px;

        .icon-chevron-right:before {
            display: inline-block;
            font-size: 1.15em;
            margin-left: 8px;
            margin-top: 4px;
            vertical-align: top;
        }
    }

    .navbar-brand {
        color: $nav-link-primary;
        height: 37px;
        padding: 7px 10px 0;

        img {
            width: 130px;
        }

        &:focus {
            outline: none;
        }
    }

    .navbar-toggle + .navbar-brand {
        padding-left: 0;
    }

    &.no-sticky {
        position: absolute;
    }

    .navbar-toggle {
        font-size: 9px;
        font-weight: 800;
        margin: 0;
        padding: 7px 6px 0 10px;
        text-transform: uppercase;
    }

    .icon-bar {
        @include border-radius(6px);
        height: 2px;
        width: 28px;

        & + .icon-bar {
            margin-top: 4px;

            &:last-of-type {
                margin-bottom: 3px;
            }
        }
    }

    .close-site-nav {
        color: $light;
        font-size: em(50);
        left: 30px;
        opacity: 0;
        position: absolute;
        text-decoration: none;
        top: -10px;
        transition: opacity 0.3s;
    }

    .navbar-fixed-bottom {
        border-top: 2px solid rgba($light, .3);
        color: $light;
        padding: 30px 30px 30px 50px;
        position: absolute;

        .invocaNumber {
            &:after {
                content: none;
            }
        }

        p {
            font-size: 14px;
            line-height: 23px;
        }

        .btn-primary {
            font-size: 24px;
            font-weight: 700;
            line-height: 23px;
        }
    }

    .navbar-collapse {
        background-color: $site-nav-bg;
        border: none;
        height: 100%;
        left: -100%;
        max-width: 370px;
        min-height: 100%;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        overflow-x: hidden;
        position: fixed;
        top: 0;
        transition: left 0.3s linear;
        width: 100%;
    }

    .navbar-nav {
        margin-top: 80px;
        position: relative;
        right: 0;
        transition: right 0.2s ease;

        & > li {
            position: static;
        }

        & > li > a {
            font-size: em(17);
            font-weight: 400;
            padding-bottom: 30px;
            padding-left: 50px;

            &:hover,
            &:focus {
                color: $light;
            }
        }

        .last-item {
            display: none;
        }
    }

    .dropdown.open {
        background-color: $site-nav-bg;

        & > a {
            background-color: $site-nav-bg;
            color: $light;
        }

        & .dropdown-menu {
            height: 288px;
            left: auto;
            overflow-y: auto;
            position: absolute;
            right: -100%;
            top: 0;
            width: 100%;

            @media (min-height: 790px) {
                height: auto;
                overflow-y: auto;
            }
        }

        & .dropdown-menu > li > a {
            padding: 10px 0 10px 80px;

            &:hover {
                color: $light;
            }

            &.slide-back {
                font-size: em(17);
                font-weight: 600;
                padding-left: 50px;

                &:before {
                    content: "\e900";
                    display: inline-block;
                    left: -15px;
                    margin-right: -6px;
                    position: relative;

                    top: 4px;

                    font: {
                        family: li-org;
                        size: 1.2em;
                        weight: 100;
                    }
                    @include rotate(180deg);
                }
            }

            &.mobile-style {
                color: $li-blue;
                font-size: em(19);
                font-weight: 600;
                margin-bottom: 10px;
                margin-top: 10px;
                padding-left: 60px;
            }
        }
    }

    .dropdown-menu {
        @include border-radius(0);

        & > li > a {
            font-size: em(12);
            line-height: 41px;
        }
    }

    .dropdown-toggle {
        &:after {
            content: "\e900";
            display: inline-block;
            margin-left: 6px;
            position: relative;

            top: 2px;
            transition: transform 0.2s ease;

            font: {
                family: li-org;
                size: 1.2em;
                weight: 100;
            }
        }
    }

    .branded-logo {
        margin-left: -8px;
        padding: 0;
    }

    .ty-final-expense .branded-logo img {
        height: 48px;
        width: 97px;
    }

    .navbar-header.add-logo .navbar-brand {
        .logo-sm {
            height: 27px;
            width: 42px;
        }
    }

    &.no-cta {
        .navbar-header.add-logo .branded-logo {
            float: right;
            margin-right: 10px;
        }
    }
    // brand specific styles
    &.united-of-omaha {
        .navbar-brand {
            &.branded-logo {
                position: relative;

                img {
                    margin-top: 5px;
                    width: 165px;
                }

                &:before {
                    background-color: $rule;
                    content: "";
                    display: inline-block;
                    height: 30px;
                    left: -5px;
                    position: absolute;
                    top: 5px;
                    width: 1px;
                }
            }
        }
    }
}

@mixin site-nav--min-sm() {
    .site-nav {

        &.navbar {
            min-height: 90px;
        }

        .navbar-brand {
            padding-left: 10px;
            padding-top: 24px;

            img {
                width: 220px;
            }
        }

        .cta-buttons {
            display: inline-block;
            float: right;
            margin-top: 32px;
            vertical-align: top;

            a {
                font-size: 21px;
                font-weight: 700;
                line-height: 28px;

                &:hover {
                    color: $li-blue;
                }
            }
        }

        .navbar-toggle {
            color: $li-purple;
            font-size: 17px;
            margin-bottom: 12px;
            margin-top: 12px;
            padding: 7px 10px 0;
        }

        .icon-bar {
            height: 4px;
            width: 52px;

            & + .icon-bar {
                margin-top: 8px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        .navbar-fixed-bottom {

            p {
                font-size: 14px;
                line-height: 23px;
            }

            .btn-primary {
                background: none;
                border: 0;
                color: $li-blue;
                font-size: 28px;
                line-height: 23px;
                padding: 0;
            }
        }

        .navbar-collapse {
            width: 50%;
        }

        .close-site-nav {
            font-size: em(65);
            font-weight: 400;
        }

        .navbar-nav {
            & > li > a {
                font-size: em(21);
                padding-bottom: 40px;
            }

            & .last-item{
                & .is--cellphone:hover{
                    color: $li-blue;
                }
            }
        }

        .dropdown.open {
            & .dropdown-menu > li > a {
                font-size: em(14);

                &.slide-back {
                    font-size: em(21);
                    margin-bottom: 20px;
                }

                &.mobile-style {
                    color: $li-blue;
                    font-size: em(19);
                    font-weight: 600;
                    margin-bottom: 10px;
                    margin-top: 10px;
                    padding-left: 60px;
                }
            }
        }

        .branded-logo {
            padding-left: 10px;
            padding-top: 16px;
        }

        .ty-final-expense .branded-logo img {
            height: 74px;
            width: 161px;
        }

        &.no-cta {
            .navbar-header.add-logo .branded-logo {
                float: none;
                margin-right: 0;
            }
        }
        // brand specific styles
        &.united-of-omaha {
            .navbar-brand {
                &.branded-logo {
                    margin-left: 10px;
                    padding-top: 28px;

                    img {
                        margin-top: -15px;
                        width: 320px
                    }

                    &:before {
                        height: 47px;
                        left: -10px;
                        top: 22px;
                    }
                }
            }
        }
    }
}

@mixin site-nav--min-md() {
    .site-nav {
        .navbar-header {
            width: 100%;

            &.has-nav {
                width: 0;

                &.ty-final-expense {
                    width: 70%;
                }
            }
        }

        .navbar-toggle {
            display: none;
        }

        .navbar-fixed-bottom {
            display: none;
        }

        .navbar-collapse {
            background-color: $navbar-default-bg;
            max-width: 100%;
            overflow: visible; //initial (not valid in ie)
            position: static; //initial (not valid in ie)
            transition: none;
            width: 100%;
        }

        .navbar-nav {
            margin-top: 0;

            .last-item {
                display: inline-block;
                font-weight: 700;
                margin-top: 33px;
                padding: 0 20px;
                
                & .is--cellphone:hover {
                    color: $li-blue;
                }
                
            }

            & > li > a {
                color: $nav-link-primary;
                font-size: em(14);
                font-weight: 400;
                line-height: 90px;
                max-height: 90px;
                padding: 0 10px;

                &:hover,
                &:focus {
                    background-color: $li-blue;
                    color: $light;
                }
            }

            & > li > span > a:focus {
                text-decoration: none;
            }

            .dropdown.open {
                & > a {
                    background-color: $site-nav-bg;
                }

                & .dropdown-menu {
                    border: none;
                    left: auto;
                    max-width: 540px;
                    min-width: 250px;
                    padding: 8px 0 0 0;
                    right: auto;
                    top: auto;
                    width: auto;

                    &.add-column {
                        column-count: 2;
                        column-gap: 0;
                        min-width: 545px;

                        li:nth-child(n+8) > a {
                            padding-left: 15px;

                            &.button-style {
                                margin-left: 0px;
                            }
                        }
                    }
                }

                & .dropdown-menu > li > a {
                    font-size: em(12);
                    padding: 2px 30px;

                    &:hover {
                        color: $li-blue;
                        font-weight: 600;
                    }

                    &.button-style {
                        background-color: $li-blue;
                        color: $light;
                        font-size: 14px;
                        font-weight: 600;
                        margin: 0 15px 15px;
                        padding-left: 15px;
                        padding-right: 15px;

                        &:hover {
                            background-color: $light;
                            color: $li-blue;
                        }
                    }
                }

                .dropdown-toggle {
                    &:after {
                        @include rotate(90deg);
                    }
                }
            }
        }

        .close-site-nav {
            display: none;
        }

        .branded-logo {
            padding-left: 31px;
            padding-top: 17px;
        }

        &.nav-typ {
            .navbar-nav .last-item a {
                font-size: 20px;
            }
        }
    }
}

@mixin site-nav--min-xg() {
    .site-nav {

        &.navbar {
            min-height: 90px;
        }

        .navbar-brand {
            padding-left: 10px;
            padding-top: 24px;

            > img {
                margin-left: -5px;
                width: 220px;
            }
        }

        .navbar-nav {
            & > li > a {
                padding: 0 25px;
            }

            & > li > span > a:focus {
                text-decoration: none;
            }

            & > li > span > a:hover {
                color: $li-blue;
            }
        }
    }

    .branded-logo {
        padding-left: 31px;
        padding-top: 17px;
    }
}