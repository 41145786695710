.standout-hero {
    background-color: $li-purple;
    background-repeat: no-repeat;
    background-size: cover;
    color: $light;
    padding: 40px 0 50px;
    text-align: center;

    h1 {
        color: inherit;
        font-size: 25px;
        line-height: 1.2;
        margin-bottom: 26px;
    }

    h2 {
        color: inherit;
        font-size: 18px;
        font-weight: 300;
        line-height: 1.34;
    }

    .formZip {
        font-size: 16px;
        margin: 30px auto;
        max-width: 176px;
        position: relative;

        input {
            @include placeholder-label();
            color: $form-input;
            font-size: 1em;
            padding: 8px 20px;
            width: 100%;

            & + label {
                font-size: 1em;
                font-weight: 400;
                left: 20px;
            }
        }

        .btn {
            background-color: $li-blue;
            border-color: $li-blue;
            font-size: 1em;
            font-weight: 600;
            margin-top: 20px;
            padding: 10px 12px;
            width: 100%;

            & > span:after {
                font-weight: 400;
            }
        }
    }

    .field-validation-error {
        font-weight: 600;
        position: absolute;
        top: 45px;
        white-space: normal;
        width: 100%;
    }
    /* --- start: co-branded variants --- */
    // Type 1 (default)
    &.branded-standout-hero {
        padding: 30px 0 10px;

        .hero-content {
            margin: 0 auto;
            max-width: 290px;
            text-align: left;
        }

        h1 {
            font-size: 23px;
            font-weight: 300;
            line-height: 1.391;
            margin-bottom: 19px;
        }

        h2 {
            font-size: 13px;
            font-weight: 600;
            line-height: 1.769;
            margin-bottom: 19px;
        }

        sup {
            font-size: 60%;
            top: -0.75em;
        }

        .formZip {
            max-width: 290px;

            label {
                font-size: 14px;
            }

            input {
                max-width: 140px;
            }

            .btn {
                font-size: 14px;
                margin-left: 5px;
                margin-top: 0;
                max-width: 140px;
                padding: 12px;
                vertical-align: top;
            }

            .visible-xs {
                display: inline !important;
            }
        }
    }
    // End type 1
    // Type 2
    &.standout-hero-type-2 {
        background-color: $light;

        .hero-content {
            color: $li-purple;
            margin-bottom: 100px;
            max-width: 320px;
            text-align: center;
        }

        h1 {
            font-size: 25px;
        }

        h2 {
            font-size: 16px;
        }

        .btn {
            font-weight: 400;
        }

        .field-validation-error {
            text-align: left;
        }
    }
    // End type 2
    /* --- end: co-branded variants --- */
}

.only-background-image {
    background-repeat: no-repeat;
    background-size: cover;
    height: 170px;
}

@mixin standout-hero--min-sm() {
    .standout-hero {
        padding: 60px 0 120px;

        .hero-content {
            margin: 0 auto;
            max-width: 390px;
        }

        h1 {
            font-size: 27px;
            font-weight: 300;
            line-height: 1.375;
        }

        h2 {
            font-size: 20px;
        }

        .formZip {
            max-width: 370px;

            input {
                max-width: 176px;
            }

            .btn {
                margin-left: 12px;
                margin-top: 0;
                max-width: 176px;
                vertical-align: top;
            }
        }

        .field-validation-error {
            position: static;
            white-space: normal;

            & > span {
                font-size: 13px;
                position: absolute;
                text-align: left;
                top: 48px;
            }
        }
        /* --- start: co-branded variants --- */
        // type 1 (default)
        &.branded-standout-hero {
            background-position: center;
            height: 437px;
            padding: 0;

            .hero-content {
                color: $li-mdpurple;
                margin: 64px 0 150px;
                max-width: 490px;
                text-align: left;

                .final-expense & {
                    max-width: 357px;
                }
            }

            h1 {
                font-size: 30px;
            }

            h2 {
                font-size: 18px;
            }

            .formZip {
                margin-left: 0;
                max-width: 330px;

                .btn {
                    font-size: 16px;
                    max-width: 160px;
                    padding: 10px 12px;
                }

                input {
                    max-width: 155px;
                }

                label {
                    font-size: 16px;
                }

                .visible-xs {
                    display: none !important;
                }
            }
        }
        // end type 1
        // type 2
        &.standout-hero-type-2 {
            .hero-content {
                margin-bottom: 0;
                max-width: none;
                padding-right: 5%;

                h2 {
                    max-width: 330px;
                }
            }
        }
        // end type 2
        /* --- end: co-branded variants --- */
        .only-background-image {
            display: none;
        }
    }
}

@mixin standout-hero--min-md() {
    .standout-hero {
        padding-bottom: 100px;
        padding-top: 70px;

        .hero-content {
            max-width: 555px;
        }

        h1 {
            font-size: 42px;
        }

        h2 {
            font-size: 24px;
        }
        /* --- start: co-branded variants --- */
        // type 1 (default)
        &.branded-standout-hero {
            background-position: center;
            height: 476px;

            .hero-content {
                margin: 80px 0 170px;
                max-width: 536px;

                .final-expense & {
                    max-width: 550px;
                }

                .formZip {
                    .final-expense & {
                        max-width: 365px;
                    }

                    .btn {
                        .final-expense & {
                            max-width: 185px;
                        }
                    }
                }
            }

            h1 {
                font-size: 34px;
            }

            h2 {
                font-size: 20px;
                line-height: 1.4;
            }
        }
        // end type 1
        // type 2
        &.standout-hero-type-2 {
            .hero-content {
                margin-bottom: 0;
                max-width: 697px;
                padding-right: 0;

                h2 {
                    max-width: 548px;
                }
            }
        }
        // end type 2
        /* --- end: co-branded variants --- */
    }
}

@mixin standout-hero--min-xxg() {
    .standout-hero {
        &.branded-standout-hero {
            background-size: 100% 100%;
        }
    }
}
