.adv__text-header {
  background-color: $row-bg;
  color: $li-purple;
  font-size: em(14);
  font-weight: 400;
  text-align: center;
  > p {
    margin-bottom: 0;
    padding: 12px 20px;
  }
  &.is--dark {
      background-color: $li-dkpurple;
      color: $light;
      position: relative;
  }
}
@mixin adv__text-header--min-sm() {
  .adv__text-header {
    font-size: em(16);
    > p {
      padding: 20px;
    }
  }
}