﻿.direct-people {
    background-color: $people-bg;

    .people-image {
        margin: {
            bottom: 48px;
            top: 91px;
        }

        text-align: center;

        @include breakpoint($sm) {
            margin: {
                bottom: 30px;
                top: 62px;
            }
        }

        @include breakpoint($lg) {
            margin-bottom: 21px;
        }

        img {
            height: 161px;
            width: 244px;

            @include breakpoint($sm) {
                height: 220px;
                width: 333px;
            }
        }
    }

    .people-title {
        margin: auto;
        margin-bottom: 77px;
        width: 290px;

        @include breakpoint($sm) {
            margin-bottom: 63px;
            width: 530px;
        }

        @include breakpoint($lg) {
            margin-bottom: 62px;
            width: 750px;
        }

        p {
            color: $li-purple;
            font-size: 34px;
            line-height: 38px;
            text-align: center;
			
            @include breakpoint($sm) {
				font-size: 24px;
				line-height: 24px;
            }

            @include breakpoint($lg) {
				font-size: 34px;
				line-height: 34px;
            }

            sup {
                font-size: 16px;
                top: -1em;

                @include breakpoint($sm) {
                    font-size: 11px;
                }

                @include breakpoint($lg) {
                    font-size: 16px;
                }
            }
        }
    }
}

