.adv__compare {
  background-color: $li-purple;
  color: $light;
  margin: 0 auto;
  max-width: 970px;
  padding-bottom: 30px;
  padding-top: 30px;
  text-align: center;
  p {
    font-size: em(20);
    font-weight: 400;
    line-height: 1.3em;
    margin-bottom: 30px;
    text-align: center;
  }
  form {
    display: inline-block;
    position: relative;
    input {
      color: $form-input;
      display: inline-block;
      font-size: em(16);
      font-weight: 600;
      height: 56px;
      max-width: 188px;
      min-width: 137px;
      padding-left: 16px;
      padding-right: 16px;
      width: calc(100% - 82px);
      @include placeholder-label();
      &+label {
        font-size: 1em;
        left: 16px;
        top: 16px;
      }
      &:focus + label,
      &:valid + label,
      &.not-empty + label {
        font-size: 0.8em;
        font-weight: 300;
        top: -24px;
      }
    }    
    .btn {
      font-size: em(16);
      font-weight: 600;
      height: 56px;
      margin-left: -4px;
      min-width: 82px;
      vertical-align: top;
    }
  }  
}
@mixin adv__compare--min-sm() {
  .adv__compare {
    text-align: left;
    p {
      font-size: em(22);
      margin-bottom: 0;
      padding-right: 10px;
      text-align: right;
    }
  }
}
@mixin adv__compare--min-md() {
  .adv__compare {
    p {
      margin-top: 12px;
      padding-right: 0;
    }
    form .btn {
      margin-left: 12px;
    }
  }
}
@mixin adv__compare--min-xg() {
  .adv__compare {
    max-width: none;
  }
}