.feature-col {
  &:after {
    border-left: 1px solid $content-border;
    bottom: -40px;
    content: none;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
  }
}
.featured-article {
  padding-top: 18px;
  position: relative;
  z-index: 20;
  h2 {
    font-size: em(28);
    font-weight: 300;
    line-height: 1.2em;
  }
  p {
    font-size: em(13);
    padding: 15px 0 5px;
  }
  &+.featured-article {
    margin-top: 40px;
  }
  .btn-sm > span {
    display: block;
    margin-top: 3px;
    &:after {
        content: none;
    }
  }
}
@mixin feature-col--min-md() {
    .feature-col {
        &:after {
            content: "";
        }
        &.organic-carrier-page {
            padding-top: 90px;
        }
    }
}