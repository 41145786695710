.adv__hero {
    background-color: $li-purple;
    color: $light;

    .img-col {
        background-position: 0 15%;
        background-size: cover;
        min-height: 166px;
        padding-left: 0;
        padding-right: 0;
    }

    .form-col {
        margin: 0 auto;
        max-width: 375px;
        padding: 20px 35px;
    }

    h1 {
        color: inherit;
        font-size: em(24);
        font-weight: 300;
        line-height: 1.3em;
        margin-bottom: 18px;
    }
    h2 {
        color: inherit;
        font-size: 16px;
        font-weight: 700;
    }

    p {
        font-size: em(14);
        margin: 15px 0 0;

        a {
            font-weight: 600;
            white-space: nowrap;
        }
    }

    form {
        margin: 24px auto;
        max-width: 180px;
        position: relative;

        input {
            color: $form-input;
            display: block;
            font-size: em(16);
            font-weight: 600;
            height: 44px;
            margin: 0 auto;
            max-width: 180px;
            padding-left: 16px;
            padding-right: 16px;
            @include placeholder-label();

            & + label {
                font-size: 1em;
                left: 16px;
                top: 10px;
            }

            &:focus + label,
            &:valid + label,
            &.not-empty + label {
                font-size: 0.8em;
                font-weight: 300;
                top: -24px;
            }
        }

        .btn {
            background-color: $li-blue;
            border-color: $li-blue;
            font-size: em(16);
            font-weight: 600;
            height: 44px;
            margin-top: 20px;
            width: 180px;
        }

        .field-validation-error {
            position: absolute;
            text-align: center;
            top: 43px;
            width: 100%;
        }
    }
}

@mixin adv__hero--min-sm() {
    .adv__hero {
        .container {
            width: 100%;
        }

        h1 {
            font-size: em(24);
        }

        h2 {
            color: inherit;
            font-size: 16px;
            font-weight: 600;
        }

        .img-col {
            min-height: 296px;
        }

        .form-col {
            max-width: none;
            padding: 25px 12% 0 50px;
        }

        form {
            .btn {
                margin-left: 6px;
            }
        }

        p {
            margin-top: 20px;

            .is--cellphone:hover {
                color: $li-blue;
            }
        }

        &.hero-sm {

            .img-col {
                min-height: 350px;
            }

            .form-col {
                min-height: 350px;
                padding: 25px 0 0 30px;
                position: relative;
                & > div {
                    @include center-block-vertical();
                    padding-right: 5%;
                }
            }
            form {
                max-width: none;
                input {
                    display: inline-block;
                    max-width: 136px;
                    & + label {
                        left: 8px;
                    }
                }
                .btn {
                    margin-top: 0;
                    vertical-align: top;
                }
                .field-validation-error {
                    position: static;
                    text-align: left;
                    top: auto;
                    width: auto;
                }
            }
        }
    }
}

@mixin adv__hero--min-lg() {
    .adv__hero {
        .img-col {
            min-height: 415px;
        }

        .form-col {
            padding-right: 0;
        }

        h1 {
            font-size: em(34);
            max-width: 464px;
        }

        h2 {
            font-size: 20px;
            max-width: 455px;
        }

        form {
            input {
                max-width: 212px;
            }
        }

        &.hero-sm {
            .img-col {
                min-height: 415px;
            }

            .form-col {
                min-height: 415px;
                padding-left: 40px;
                > div {
                    padding-right: 0;
                }
            }
            h1, h2 {
                max-width: 540px;
            }
            form {
                margin-top: 40px;

                input {
                    max-width: 213px;

                    & + label {
                        left: 50px;
                    }

                    &:focus + label,
                    &:valid + label,
                    &.not-empty + label {
                        font-weight: 600;
                        left: 0;
                    }
                }
            }
        }
    }
}
@mixin adv__hero--min-xg() {
    .adv__hero {
        &.hero-sm {
            h1, h2 {
                max-width: 550px;
            }
            .img-col {
                min-height: 430px;
            }
            .form-col {
                min-height: 430px;
                padding-left: 70px;
            }
        }
    }
}
@mixin adv__hero--min-xxg() { 
    .adv__hero {
        &.hero-sm {
            .img-col {
                background-position: right;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }
}