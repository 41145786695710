// QUIZ SPECIFIC
$answer-selected: rgba(255, 255, 255, .2);
// END QUIZ SPECIFIC
#component__quiz {
    // TRANSITION STYLES
    .question-wrap {
        overflow: hidden;
        padding-bottom: 40px;
        position: relative;
        white-space: nowrap;
    }

    .step {
        display: inline-block;
        vertical-align: top;
        white-space: normal;
        width: 100%;
    }

    .slide {
        &-enter-active, &-leave-active {
            transition: all 0.4s ease;
        }

        &-enter, &-leave-to {
            opacity: 0;
        }

        &-enter-to, &-leave-to {
            transform: translateX(-100%);
        }
    }

    &.goPrev .slide {
        &-enter {
            transform: translateX(-200%);
        }

        &-enter-to {
            transform: translateX(-100%);
        }

        &-leave-to {
            transform: translateX(100%);
        }
    }
    // END TRANSITION STYLES
    .quiz {
        background: $li-blue;
        color: $light;
        position: relative;
        > .container {
            max-width: 100%;
        }
        .initial {
            min-height: 400px;
            overflow: hidden;       // ie fix (applied to parent of transformed item)
            position: relative;
            text-align: center;

            .wrap {
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
            }

            .btn-default {
                border: 1px solid $border-line;
                border-radius: 3px;
                color: $text-primary;
                font-size: 30px;
                font-weight: 700;
                margin-top: 20px;
                max-width: 300px;
                width: 100%;
            }

            p {
                font-size: 24px;
            }
        }

        .questions__steps {
            list-style: none;
            margin: 0 auto;
            max-width: 800px;
            min-height: 480px;
            overflow: hidden;
            padding: 0;
            position: relative;

            .question-title {
                color: $light;
                font-size: 20px;
                font-weight: 400;

                b, span {
                    display: inline-block;
                    vertical-align: top;
                }

                b {
                    position: relative;
                    top: -2px;
                    width: 30px;
                }

                span {
                    width: 85%;
                }
            }


            .icon:before {
                display: inline-block;
                font-size: 10px;
                position: relative;
                top: -2px;
            }

            .step__wrapper {
                margin-top: 10%;
                padding: 30px 20px 40px 0;
            }

            .step__answer {
                display: inline-block;
                list-style: none;
                margin-left: 40px;
                padding: 0;

                input[type='radio'] {
                    display: none;
                }

                label {
                    border: 1px solid $light;
                    border-radius: 3px;
                    cursor: pointer;
                    margin-top: 10px;
                    padding: 12px 40px 12px 10px;
                    position: relative;
                    width: 100%;

                    &:hover {
                        background: $answer-selected;
                    }

                    i {
                        float: right;
                        vertical-align: top;

                        &:before {
                            content: '\2713';
                            opacity: 0;
                            position: absolute;
                            right: 15px;
                        }
                    }

                    .letter, .answer-title {
                        display: inline-block;
                        vertical-align: top;
                    }

                    .letter {
                        background: rgba($light, .2);
                        border: 1px solid $light;
                        border-radius: 3px;
                        font-size: 12px;
                        height: 20px;
                        left: 10px;
                        margin-right: 5px;
                        margin-top: 3px;
                        position: absolute;
                        text-align: center;
                        width: 20px;
                    }

                    .answer-title {
                        float: left;
                        margin-left: 30px;
                        padding-right: 10px;
                    }
                }

                input[type=radio]:checked + label {
                    animation: pulse .45s ease-in-out;
                    background: $answer-selected;

                    i:before {
                        opacity: 1;
                    }
                }
            }
        }

        .ty-screen {
            font-size: 18px;
            margin: 0 auto;
            max-width: 800px;
            padding: 50px 0;
            text-align: center;

            strong {
                font-size: 24px;
            }

            .btn {
                font-size: 18px;
                margin: 20px 0 10px 0;
            }
        }

        .controls {
            background: rgba($dark, .1);
            bottom: 0;
            height: 60px;
            left: 0;
            position: absolute;
            width: 100%;

            & > .container {
                max-width: 800px;
            }

            .progress-section, .buttons {
                display: inline-block;
                vertical-align: top;
            }

            .progress-section {
                font-weight: 600;
                margin-top: 10px;
                max-width: 200px;
                width: 50%;

                small {
                    font-size: 80%;
                    opacity: 0.8;
                }

                .progress {
                    background: $answer-selected;
                    height: 5px;

                    .progress-bar {
                        background-color: $light;
                    }
                }
            }

            .buttons {
                float: right;
                margin-top: 11px;

                .btn {
                    font-size: 18px;
                }
            }
        }
    }
    &+p {
        margin-top: 30px;
    }
}

@mixin quiz--min-md() {
    #component__quiz {
        .quiz {
            .questions__steps {
                .step__wrapper {
                    margin-top: 5%;
                }
            }
        }
    }
}

@include keyframes(pulse) {
    25%, 75% {
        background: none;
    }

    0%, 50%, 100% {
        background: $answer-selected;
    }
}