// General

// Colors
$li-blue: #1f9ce8;
$li-ltblue: #2AABF7;
$li-purple: #412d57;
$li-ltpurple: #5b3c7d;
$li-mdpurple: #3a1d59;
$li-dkpurple-alt: #563D70;
$li-dkpurple: #231132;
$li-green: #76ceba;
$li-ltgray: #828282;
$li-ltgray-alt: #6E6E6E;
$li-ltgray-alt-2: #5D5D5D;
$li-mdgray: #54565C;
$li-mdgray-alt: #CDCDCD;
$li-pink: #bf2277;

$li-card-title: #472c64;
$li-card-subtitle: $li-ltgray-alt-2;

// Other Brand Colors
$jh-purple: #1c0f2b;
$footer-text-color: #ada2b2;
$logos-dgray: #646464;

$border-lt: #e9e9e9;
$border-line: #979797;
$select-text: #676767;
$border-drip: #969696;

$purple: #231033;
$light: #ffffff;
$dark: #000000;
$light-alt: #ead9f2;
$light-alt-2: #FAFAFA;
$header-primary: $li-mdpurple;
$text-primary: #3f3f3f;
$link-primary: $li-blue;
$nav-link-primary: $li-ltgray;
$footer-legal: $light-alt;
$footer-nav: $light-alt;
$table-border: rgba(58, 29, 89, 0.18);
$content-border: rgba(130, 130, 130, 0.1);
$learnmore-border: rgba(130, 130, 130, 0.15);
$rule: #d8d8d8;
$progress-bar: #745899;

// Buttons
$btn-primary-bg: $link-primary;
$btn-default-color: $li-blue;
$btn-default-border: $btn-default-color;
$btn-action-bg: $li-pink;
$btn-action-color: $light;

// Backgrounds
$footer-bg: #1c0f2b;
$purple-bg: $li-purple;
$blue-bg: $li-blue;
$site-nav-bg: rgba(0, 0, 0, 0.95);
$superfooter-bg : rgba(141, 122, 162, 0.15);
$row-bg: #f8f8f8;
$lt-bg: #fafafa;
$more-info-bg: #eeebf1;
$ribbon-bg: $more-info-bg;
$people-bg: $more-info-bg;

// Form fields
$form-input: #676767;
$form-error: #ff0000;
$form-error-bg: #ffe5e5;

// Typography
$font-family-sans-serif:  "Open Sans", sans-serif;
// available font-weights for Open Sans:
// - 300: Light
// - 400: Regular
// - 600: Semi-Bold
// - 700: Bold
// - 800: Extra-Bold
$font-size-base: 16px;

// Scaffolding
$text-color: $text-primary;
$link-color: $link-primary;
$input-border-focus: #b7ddf3;
$headings-color: $header-primary;
$container-md: 970px;
//$container-large-desktop: 970px;
$grid-float-breakpoint: 992px;
$navbar-height: 42px;
$navbar-border-radius: 0;
$navbar-default-color: $nav-link-primary;
$navbar-default-link-color: $light;
$navbar-default-link-active-bg: $site-nav-bg;
$navbar-default-link-active-color: $light;
$navbar-default-bg: $light;
$navbar-default-toggle-icon-bar-bg: $purple-bg;
$navbar-default-toggle-border-color: transparent;
$navbar-default-toggle-hover-bg: transparent;
$dropdown-bg: $site-nav-bg;
$dropdown-border: $site-nav-bg;
$dropdown-link-color: $light;
$dropdown-link-hover-bg: $dark;
$dropdown-link-hover-color: $li-blue;
$btn-border-radius-base: 0;
$btn-border-radius-small: 0;
$btn-border-radius-large: 0;
$z-index-top: 1060; // based from bootstrap z-indexing of fixed elements