﻿.companies-bar {
    background-color: $row-bg;
    font-size: 40px;
    padding: 20px 0 10px;
    text-align: center;
    li:last-of-type {
        margin-top: 20px;
    }
    .icon-aig-logo {
        font-size: 32px;
    }
    .icon-jh-logo {
        font-size: 50px;
        position: relative;
    }
}
@mixin companies-bar--min-sm() {
    .companies-bar {
        padding: 10px 0 0;
        li {
            display: inline-block;
            padding-right: 60px;
            &:last-of-type {
                margin-top: 0;
                padding-right: 0;
            }
        }
        .icon-jh-logo {
            top: 10px;
        }
    }
}