.adv__content-grid {
    padding: 20px 0 10px;

    .image-col {
        padding: 0 40px;

        &.is--first {
            padding-top: 10px;
        }

        > div {
            background-position: 50% 20%;
            background-repeat: no-repeat;
            background-size: cover;
            height: 190px;
            margin: 0 auto;
            max-width: 468px;
        }
    }

    .text-col {
        padding: 0 40px;
        position: relative;

        > div {
            margin: 0 auto;
            max-width: 385px;
            padding: 40px 0 30px;
        }

        h2 {
            font: {
                size: 16px;
                weight: bold
            }

            color: $text-primary;
            line-height: 24px;
        }

        p, li {
            font-size: 12px;
            font-weight: normal;
            line-height: 1.5;
        }

        a[href^="tel:"] {
            font-weight: 600;
        }
    }

    &.is--branded {
        padding-top: 0;

        .image-col {
            &.is--first {
                > div {
                    background-position: 80% 20%;
                }
            }

            > div {
                height: 370px;
            }
        }

        .text-col {
            h2 {
                color: $li-mdpurple;
                font-size: 25px;
                font-weight: 300;
                line-height: 34px;
            }

            p {
                font-size: 14px;
                font-weight: 300;
                line-height: 21px;
                padding-right: 5px;
            }
        }
    }

    &.is--fullwidth {
        padding: 0 0 10px;

        .image-col {
            max-width: 100%;
            padding: 0;
        }
        .text-col {
            padding: 0 20px;
            p {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}
@mixin adv__content-grid--min-sm() {
    .adv__content-grid {
        padding: 30px 0 50px;

        h2 {
            font-size: em(29);
        }

        .image-col {
            padding-left: 0;
            padding-right: 0;

            > div {
                height: 345px;
                max-width: 345px;

                &.content-image-1 {
                    margin-right: 0;
                }

                &.content-image-2 {
                    margin-left: 0;
                }
            }
        }

        .text-col {
            height: 345px;
            padding-left: 0;
            padding-right: 0;

            > div {
                @include center-block-vertical();
                max-width: 325px;
                padding: 0 30px;

                &.content-text-1 {
                    left: 30px;
                }

                &.content-text-2 {
                    right: 20px;
                }

                .is--cellphone:hover { 
                    color: $li-blue;
                }
            }
        }

        &.is--branded {
            padding-bottom: 70px;

            .image-col {
                > div {
                    height: 370px;
                }
            }

            .text-col {

                > div {
                    &.content-text-1 {
                        padding-left: 37px;
                        padding-right: 64px;

                        p {
                            padding-right: 13px;
                        }
                    }

                    &.content-text-2 {
                        padding-left: 55px;
                        padding-right: 52px;
                        padding-top: 30px;

                        p {
                            padding-right: 7px;
                        }
                    }
                }

                h2 {
                    color: $li-mdpurple;
                    font-size: 30px;
                    line-height: 41px;
                }

                p {
                    font-size: 14px;
                    line-height: 21px;
                }
            }
        }

        &.is--fullwidth {
            padding: 40px 0 40px;

            .container {
                padding: 0;
            }

            .image-col {
                max-width: 100%;
                padding: 0;

                .content-image-1,
                .content-image-2 {
                    max-width: 100%;
                }
            }

            .text-col {
                padding: 0 20px;

                p {
                    font-size: 16px;
                    line-height: 24px;
                }

                > div {
                    &.content-text-1,
                    &.content-text-2 {
                        padding: 0;    
                    }
                }
            }
        }
    }
}
@mixin adv__content-grid--min-md() {
    .adv__content-grid {
        h2 {
            font-size: em(34);
        }

        .image-col {
            > div {
                height: 450px;
                max-width: 450px;
            }
        }

        .text-col {
            height: 450px;

            > div {
                max-width: 395px;
                padding: 0 50px;

                &.content-text-1 {
                    left: 40px;
                }

                &.content-text-2 {
                    right: 50px;
                }
            }

            h2 {
                font-size: 18px;
            }

            p, li {
                font-size: 14px;
            }
        }

        &.is--branded {
            .image-col {
                &.is--first {
                    > div {
                        background-position: 50% 20%;
                    }
                }

                > div {
                    height: 365px;
                }
            }

            .text-col {
                height: 365px;

                > div {
                    &.content-text-1 {
                        padding-right: 48px;
                    }

                    &.content-text-2 {
                        padding-left: 63px;
                        padding-right: 36px;

                        p {
                            padding-right: 5px !important;
                        }
                    }
                }

                h2 {
                    color: $li-mdpurple;
                    font-size: 34px;
                    line-height: 46px;
                }

                p {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }

        &.is--fullwidth {
            padding: 70px 0 50px;

            .image-col {

                .content-image-1,
                .content-image-2 {
                    max-width: 450px;
                }
            }

            .text-col {
                padding: 0 20px;

                p {
                    font-size: 16px;
                    line-height: 24px;
                }

                > div {
                    &.content-text-1,
                    &.content-text-2 {
                        max-width: 395px;
                        padding: 0;
                    }

                    &.content-text-2 {
                        padding-left: 30px;
                    }
                }
            }
        }
    }
}