.revamp {
  .site-nav {
    box-shadow: none;
    min-height: 48px;

    @include breakpoint($sm) {
      border-bottom: 1px solid $border-line;
      min-height: 70px;
    }

    .navbar-brand {
      height: auto;
      margin: {
        left: 8px;
        top: 10px;
      };
      padding: 0;

      @include breakpoint($small) {
        margin-left: 22px;
      }

      @include breakpoint($xg) {
        margin-left: 12px;
      }

      img {
        width: 144px;

        @include breakpoint($sm) {
          width: 220px;
        }
      }
    }

    .cta-buttons {
      @include breakpoint($sm) {
        margin-top: 20px;
      }

      @include breakpoint($xg) {
        margin-right: 14px;
      }
    }

    .btn-primary {
      font: {
        size: 13px;
        weight: 700;
      }
      height: 48px;
      line-height: 18px;
      padding: 14px 15px;

      @include breakpoint($small) {
        padding: 14px 23px;
      }

      .icon-chevron-right:before {
        font: {
          size: 18px;
          weight: 100;
        }
        margin-top: 0;
      }
    }
  }

  .adv__header {
    padding: {
      bottom: 9px;
      top: 8px;
    };

    &-affix {
      min-height: 48px;
    }

    .container-fluid {
      @include breakpoint($xg) {
        max-width: 1150px;
        width: 100%;
      }
    }

    .brand {
      @include breakpoint($small) {
        margin-left: 10px;
        width: 144px;
      }

      @include breakpoint($sm) {
        margin-left: 44px;
        width: 220px;
      }

      @include breakpoint($xg) {
        margin-left: 0;
      }
    }

    .tel {
      @include breakpoint($sm) {
        margin-right: 49px;
      }

      @include breakpoint($xg) {
        margin-right: 0;
      }
    }

    .header-cta {
      font: {
        size: 13px;
        weight: 700;
      }
      line-height: 18px;
      min-height: 48px;
      padding: 12px 15px;

      @include breakpoint($small) {
        padding: 12px 23px;
      }

      span:after {
        font: {
          size: 18px;
          weight: 100;
        }
        top: 3px;
      }
    }
  }

  .adv__hero {
    .img-col {
      background: {
        repeat: no-repeat;
        size: cover;
      } ;
      display: none;

      @include breakpoint($sm) {
        background-position: -176px 0;
        display: block;
        min-height: 651px;
        width: 50%;
      }

      @include breakpoint($xg) {
        background-position: 0;
        min-height: 525px;
      }
    }

    .form-col {
      max-width: 100%;
      padding: 48px 32px;
      text-align: center;

      @include breakpoint($sm) {
        min-height: 651px;
        padding: 72px 58px;
        text-align: left;
        width: 50%;
      }

      @include breakpoint($xg) {
        min-height: 525px;
        padding: 80px 81px;
      }

      > div {
        padding: 0;
        position: initial;
        transform: none;

        @include breakpoint($sm) {
          max-width: 268px;
          width: 100%;
        }

        @include breakpoint($xg) {
          max-width: 449px;
        }
      }
    }

    h1 {
      font: {
        size: 28px;
        weight: 300;
      }
      line-height: 38px;
      margin-bottom: 25px;

      @include breakpoint($sm) {
        font-size: 34px;
        line-height: 45px;
      }

      sup {
        font-size: 60%;
      }
    }

    h2 {
      font: {
        size: 18px;
        weight: 600;
      }
      line-height: 28px;

      @include breakpoint($sm) {
        font-size: 20px;
        line-height: 30px;
      }
    }

    form {
      margin: 40px auto 25px;
      max-width: 264px;
      width: 100%;

      @include breakpoint($sm) {
        margin: 32px 0 25px;
      }

      @include breakpoint($xg) {
        max-width: 100%;
      }

      input {
        border: none;
        font-size: 20px;
        height: 60px;
        margin: 0;
        max-width: 100%;
        width: 100%;

        @include breakpoint($xg) {
          float: left;
          max-width: 170px;
        }

        &:focus + label,
        &:valid + label,
        &.not-empty + label {
          display: none;
        }

        + label {
          color: $border-line;
          font: {
            size: 20px;
            weight: 400;
          }
          left: 65px;
          line-height: 20px;
          top: 20px;

          @include breakpoint($sm) {
            left: 26px;
          }

          @include breakpoint($xg) {
            left: 18px;
          }
        }
      }

      .field-validation-error {
        top: -24px;

        @include breakpoint($sm) {
          position: absolute;
        }
      }

      .btn {
        font: {
          size: 20px;
          weight: 700;
        }
        height: 60px;
        line-height: 20px;
        margin: 0;
        width: 100%;

        @include breakpoint($xg) {
          max-width: 245px;
        }

        span:after {
          font: {
            size: 24px;
            weight: 100;
          }
          margin-left: 4px;
          top: 3px;
        }
      }
    }

    p {
      font: {
        size: 20px;
        weight: 400;
      }
      line-height: 20px;

      span {
        display: none;

        @include breakpoint($xg) {
          display: initial;
        }
      }

      a {
        font: {
          size: 20px;
          weight: 700;
        }
      }
    }
  }

  #main {
    background: $lt-bg;
    color: $text-primary;
    font: {
      size: 20px;
      weight: 400;
    }
    line-height: 30px;
    text-align: center;

    &:before {
      content: none;
    }

    .bg-white {
      background: $light;
      padding: 48px 32px;

      @include breakpoint($sm) {
        padding: 72px 32px;
      }

      @include breakpoint($xg) {
        padding: 80px 32px;
      }

      h2 {
        margin-bottom: 30px;

        @include breakpoint($sm) {
          font-size: 35px;
          line-height: 48px;
          margin: 0 auto 30px;
          max-width: 640px;
          width: 100%;
        }

        @include breakpoint($xg) {
          max-width: 928px;
        }
      }

      p {
        margin: 0;

        @include breakpoint($sm) {
          margin: 0 auto;
          max-width: 640px;
          width: 100%;
        }

        @include breakpoint($xg) {
          max-width: 928px;
        }
      }
    }

    .bg-gray {
      padding: 48px 32px 0;

      @include breakpoint($sm) {
        padding: 72px 64px 0;
      }

      @include breakpoint($xg) {
        padding-top: 70px;
      }

      h2 {
        margin-bottom: 24px;

        @include breakpoint($sm) {
          font-size: 34px;
          line-height: 40px;
          margin-bottom: 32px;
        }

        @include breakpoint($xg) {
          margin-bottom: 40px;
        }
      }
    }

    h2 {
      font: {
        size: 28px;
        weight: 300;
      }
      line-height: 38px;
    }

    .block-cta {
      background: $light;
      border: 2px solid $border-lt;
      margin: 24px 32px;
      padding: 0;
      
      @include breakpoint($sm) {
        align-items: center;
        display: flex;
        margin: 24px auto;
        max-width: 640px;
        width: 100%;
      }

      @include breakpoint($xg) {
        display: inline-block;
        margin: 0 13px;
        max-width: 353px;
        vertical-align: top;
      }

      &-image {
        margin: 0 auto;
        max-width: 100%;
        padding: 44px 0 25px;

        @include breakpoint($sm) {
          margin: 0;
          max-width: 141px;
          padding: 0;
        }

        @include breakpoint($xg) {
          max-width: 100%;
          padding: 43px 0 25px;
        }

        img {
          height: 79px;
          width: auto;

          @include breakpoint($sm) {
            height: 70px;
          }

          @include breakpoint($xg) {
            height: 80px;
          }
        }
      }

      &-text {
        background: $border-lt;
        padding: 40px 42px;

        @include breakpoint($sm) {
          align-items: center;
          display: flex;
          margin: 0;
          max-width: 498px;
          min-height: 120px;
          padding: 30px 35px;
          text-align: left;
          width: 100%;
        }

        @include breakpoint($xg) {
          max-width: 100%;
          padding: 40px 48px;
          text-align: center;
        }

        p {
          line-height: 30px;
          margin: 0;
        }

        sub, sup {
          font-size: 60%;
        }
      }
    }
  }

  .adv__ribbon-banner {
    background: $footer-bg;
    color: $light;
    font: {
      size: 20px;
      weight: 400;
    }
    line-height: 30px;
    margin-top: 48px;
    padding: 30px 32px;

    @include breakpoint($sm) {
      margin-top: 72px;
      padding: 30px 64px;
    }

    @include breakpoint($xg) {
      line-height: 20px;
      margin-top: 90px;
    }

    p {
      padding: 0;
    }

    sub, sup {
      font-size: 65%;
    }
  }

  .adv__apply {
    background: {
      color: $li-purple;
      position: center;
      repeat: no-repeat;
      size: cover;
    };
    color: $light;
    padding: 48px 0;

    @include breakpoint($sm) {
      padding: 80px 0;
    }

    .color-overlay {
      background-color: rgba($li-purple, 0.1);
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    h3 {
      color: $light;
      font: {
        size: 34px;
        weight: 300;
      }
      line-height: 40px;
      margin-bottom: 30px;
      padding: 0 30px;
    }

    p {
      font: {
        size: 20px;
        weight: 300;
      }
      line-height: 30px;
      margin: 0 auto;
      padding: 0 30px;
    }

    .tel {
      background: $li-blue;
      color: $light;
      display: inline-block;
      font: {
        size: 16px;
        weight: 700;
      }
      line-height: 20px;
      margin-top: 30px;
      padding: 11px 19px;
      text-decoration: none;

      &:after {
        content: "\e900";
        display: inline-block;
        font-family: li-org;
        font: {
          size: 19px;
          weight: 100;
        }
        margin-left: 8px;
        position: relative;
        top: 2px;
      }
    }
  }

  .site-footer {
    color: $footer-text-color;
    font: {
      size: 16px;
      weight: 300;
    }
    line-height: 25px;
    padding: 32px 0 33px;

    @include breakpoint($sm) {
      padding: 80px 0 42px;
    }

    @include breakpoint($xg) {
      padding-bottom: 50px;
    }

    p {
      margin-bottom: 30px;
    }

    nav {
      margin: 16px 17px;
      text-align: left;

      @include breakpoint($sm) {
        margin: 16px 41px;
      }

      @include breakpoint($xg) {
        margin: 16px 10px;
      }

      ul {
        li {
          font-size: 16px;
          font-weight: 300;

          @include breakpoint($sm) {
            display: inline-block;
            padding-right: 10px;

            &:after {
              content: "|";
              display: inline-block;
              margin-bottom: 6px;
              padding-left: 14px;
            }

            &:last-child {
              padding-right: 0;

              &:after {
                content: none;
              }
            }
          }
        }
      }
    }

    .logo {
      height: 45px;
      margin: 0 13px;

      @include breakpoint($sm) {
        margin: 0 38px;
      }

      @include breakpoint($xg) {
        margin: 0 8px;
      }
    }

    .social {
      float: none;
      margin: 30px 17px 38px;

      @include breakpoint($sm) {
        margin: 40px 41px;
      }

      @include breakpoint($xg) {
        margin: 40px 10px;
      }

      a {
        font-size: 22px;
      }
    }

    .legal {
      margin: 0 17px;

      @include breakpoint($sm) {
        margin: 0 41px;
      }

      @include breakpoint($xg) {
        margin: 0 10px;
      }

      p:first-child {
        display: none;
      }
    }
  }

  &.drip {
    padding-top: 48px;

    @include breakpoint($sm) {
      padding-top: 70px;
    }

    #main {
      background: $light;

      h2 {
        font-size: 32px;
        line-height: 44px;

        @include breakpoint($sm) {
          font-size: 35px;
          line-height: 48px;
        }
      }
    }

    .dripform {
      margin: 60px auto;
      max-width: 311px;

      @include breakpoint($sm) {
        margin: 72px auto;
        max-width: 640px;
      }

      @include breakpoint($xg) {
        margin: 80px auto;
        max-width: 1120px;
      }

      .pageWrapContainer {
        padding: 0;
      }

      .step-title {
        font-size: 32px;
        line-height: 44px;
        margin-bottom: 32px;

        @include breakpoint($sm) {
          margin-bottom: 30px;
        }
      }

      .step-subtitle {
        color: $text-primary;
        font: {
          size: 18px;
          weight: 400;
        }
        line-height: 28px;
        margin-bottom: 32px;

        @include breakpoint($sm) {
          font-size: 20px;
          line-height: 30px;
        }

        @include breakpoint($xg) {
          margin-bottom: 42px;
        }
      }

      .form-group {
        display: block;
        margin: 0 0 24px;
        text-align: center;

        @include breakpoint($xg) {
          margin-bottom: 30px;
        }
      }

      label {
        color: $text-primary;
        font: {
          size: 18px;
          weight: 600;
        }
        line-height: 18px;
        margin-bottom: 8px;

        @include breakpoint($sm) {
          font-size: 20px;
          line-height: 20px;
          text-align: center;
        }
      }

      input {
        border: 1px solid $border-drip;
        color: $border-drip;
        height: 60px;
        max-width: 100%;
        text-align: center;
        width: 100%;

        &.mask-zip {
          max-width: 225px;
        }

        @include breakpoint($sm) {
          &.mask-fullname {
            max-width: 270px;
          }

          &.mask-date {
            max-width: 168px;
          }

          &.mask-phone {
            max-width: 280px;
          }

          &.mask-email {
            max-width: 354px;
          }
        }

        @include breakpoint($xg) {
          &.mask-fullname {
            max-width: 449px;
          }

          &.mask-date {
            max-width: 280px;
          }
        }
      }

      .field-validation-error {
        max-width: 100%;
        text-align: center;

        @include breakpoint($sm) {
          position: initial;
        }
      }

      .form-controls {
        @include breakpoint($sm) {
          margin-top: 8px;
        }

        @include breakpoint($xg) {
          margin-top: 10px;
        }
      }

      button {
        display: block;
        font: {
          size: 20px;
          weight: 700;
        }
        line-height: 20px;
        margin: 0 auto;
        max-width: 100%;
        width: auto;

        span {
          position: initial;

          &:after {
            font: {
              size: 24px;
              weight: 100;
            }
            margin-left: 14px;
            top: 3px;
          }
        }
      }

      .btn-back {
        color: $li-purple;
        font: {
          size: 18px;
          weight: 600;
        }
        line-height: 18px;
        margin-top: 32px;
        text-decoration: underline;

        @include breakpoint($sm) {
          font-size: 20px;
          line-height: 20px;
        }
      }

      .btn-action {
        background-color: $li-blue;
        border-color: $li-blue;
        padding: 17px 21px 18px;

        @include breakpoint($sm) {
          padding: 17px 31px 18px;
        }
      }

      .df-disclaimer {
        font: {
          size: 16px;
          weight: 400;
        }
        line-height: 25px;
        max-width: 100%;
        padding-top: 32px;
        width: 100%;

        @include breakpoint($sm) {
          padding-top: 50px;
        }

        a {
          color: $text-primary;
          text-decoration: none;
        }
      }
    }

    .adv__ribbon-banner {
      margin-top: 0;
    }
  }
}