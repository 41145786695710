.adv__more-info {
  background-color: $more-info-bg;
  padding: 60px 0;
  h2 {
    font-weight: 100;
    text-align: center;
  }
  h3 {
    font-size: em(14);
    font-weight: 600;
    text-align: center;
  }
  form {
    margin: 45px auto 0;
    max-width: 280px;
    position: relative;
    width: 100%;
    input {
      color: $form-input;
      font-size: em(16);
      font-weight: 600;
      height: 56px;
      margin-bottom: 8px;
      max-width: 280px;
      padding-left: 16px;
      padding-right: 16px;
      width: 100%;
      @include placeholder-label();
      &+label {
        font-size: 1em;
        left: 16px;
        top: 16px;
      }
      &:focus + label,
      &:valid + label,
      &.not-empty + label {
        font-size: 0.8em;
        font-weight: 300;
        top: -24px;
      }
    }
    .btn {
      display: block;
      font-size: em(16);
      font-weight: 600;
      height: 56px;
      margin-left: auto;
      margin-right: auto;
      max-width: 280px;
      width: 100%;
    }
    small {
      display: block;
      font-style: italic;
      margin-top: 12px;
      text-align: center;
    } 
    .field-validation-error,
    .field-validation-valid,
    .field-validation-error span{
        color: red;
        font-size: 12px;
    }
  }
}
@mixin adv__more-info--min-sm() {
  .adv__more-info {
    form {
      max-width: 595px;
      input {
        margin-right: 0;
        max-width: 360px;
      }
      .btn {
        display: inline-block;
        margin-left: 8px;
        vertical-align: top;
        width: 220px;
      }
      small {
        margin-top: 0;
        text-align: left;
      }
    }
  }
}