.direct-header {
	background-color: white;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 10000;

	.container {
		max-width: 1111px;
		overflow: hidden;
		padding: 15px 0;

		@include breakpoint($sm) {
			display: flex;
		}

		div {
			@include breakpoint($sm) {
				flex-basis: 50%;
				flex-grow: 2;
			}
		}

		.logo {
			display: inline-block;
			margin-left: 50%;
			transform: translateX(-50%);

			img {
				max-width: 290px;

				@include breakpoint($sm) {
					margin-top: 15px;
					max-width: 379px;
				}
			}

			@include breakpoint($sm) {
				display: block;
				margin-left: 0;
				transform: none;
			}
		}

		.contact {
			display: none;
			text-align: right;

			@include breakpoint($sm) {
				display: block;
			}

			div {
				line-height: 1.2;
			}

			p,
			a {
				font-weight: 400;
				margin: 0;
			}

			.text {
				font-family: Arial, Helvetica, sans-serif;

				p {
					color: #564469;

					@include breakpoint($sm) {
						font-size: 14px;
					}

					@include breakpoint($lg) {
						font-size: 16px;
					}
				}
			}

			.tfn {
				color: $li-blue;
				font-size: 26px;

				& .is--cellphone:hover {
					color: $li-blue;
				}
			}

			.tty {
				p {
					color: #5d5d5d;
					font-family: $font-family-sans-serif;
					font-size: 16px;
					font-weight: 300;
				}
			}
		}
	}

	.mobile-contact {
		background-color: $light-alt-2;

		@include breakpoint($sm) {
			display: none;
		}

		.container {
			display: flex;
			max-width: 290px;
			padding: 9px 0;

			div {
				flex-basis: 50%;
				flex-grow: 2;

				&:first-of-type {
					padding-right: 8px;
				}

				&:last-of-type {
					padding-left: 8px;
					text-align: right;
				}
			}

			.btn {
				font-size: 14px;
				font-weight: 600;
				padding: 10px 13px;
			}

			p {
				font-family: Arial, Helvetica, sans-serif;
				font-size: 14px;
				margin: 0;
			}
		}
	}
}