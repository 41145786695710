.home-insurance-nav {
    color: $nav-link-primary;
    padding-top: 40px;

    h2 {
        color: $li-purple;
        font-size: em(17);
        font-weight: 600;
        line-height: em(23);
        margin-bottom: 20px;
    }

    li {
        margin-bottom: 20px;
    }

    a {
        color: $li-ltgray;
        font-size: em(14);
        font-weight: 400;
        text-decoration: none;

        &:hover {
            color: $li-pink;
        }
    }
}
@mixin home-insurance-nav--min-lg() {
  .home-insurance-nav {
    h2 {
      font-size: em(22);
    }
    a {
      font-size: em(15);
    }
  }
  .column-padding {
    padding-left: 25px;
  }
}