.home-hero {
    height: 432px;
    .text-col {
        background-position: 25% 40%;
        background-repeat: no-repeat;
        background-size: 150%;
        min-height: 180px;
        padding-left: 0;
        padding-right: 0;
    }

    .hero-text {
        padding-left: 45%;
        padding-top: 8%;
    }

    h1 {
        font-size: em(26);
        font-weight: 300;
        margin-bottom: 2px;
    }

    h2 {
        font-size: em(16);
        font-weight: 400;
    }

    .btn-primary {
        font: 700 16px/39px $font-family-sans-serif;
        margin-top: 15px;
        padding: 2px 20px;

        .icon-chevron-right:before {
            display: inline-block;
            margin-left: 10px;
            margin-top: 11px;
            vertical-align: top;
        }
    }

    .nav-col {
        margin-top: -12px;
        overflow: hidden;
        padding-left: 0;
        padding-right: 0;
    }

    nav {
        background-color: $purple-bg;
        padding: 14px 0;

        ul {
            margin-bottom: 0;
        }

        li {

            &:hover,
            &.active {
                background-color: $li-ltpurple;
            }
        }

        a {
            color: $light;
            display: block;
            font-size: em(17);
            font-weight: 600;
            line-height: 50px;
            padding: 0 10%;
            white-space: nowrap;

            &:hover,
            &:focus {
                text-decoration: none;
            }

            i:before {
                display: inline-block;
                margin-top: -2px;
                vertical-align: middle;
            }
        }
    }
}
@mixin home-hero--min-sm() {
    .home-hero {
        background-position: 30% 40%;
        background-repeat: no-repeat;
        background-size: cover;

        .text-col {
            background-image: none;
        }

        .hero-text {
            padding-left: 0;
            padding-top: 0;
            position: absolute;
            right: -325px;
            top: 20px;
        }

        h1 {
            font-size: em(44);
            margin-bottom: 6px;
        }

        h2 {
            font-size: em(26);
        }

        .nav-col {
            margin-top: 180px;
            overflow: visible;
            padding-left: 15px;
        }

        nav {
            margin-right: -9px;
            margin-top: 24px;
        }
    }
}
@mixin home-hero--min-md() {
  .home-hero {
    background-position: 25% 0;
    background-size: cover;
    height: 348px;
    .hero-text {
      padding-left: 40%;
      padding-top: 10%;
      position: relative;
      right: auto;
      top: auto;
    }
    h1 {
      font-size: em(52);
    }
    h2 {
      font-size: em(30);
    }
    .nav-col {
      margin-top: 0;
    }    
    nav {
      margin-left: -20px;
      margin-top: 0;
      a {
        line-height: 80px;
      }
    }
  }
}