﻿.subfooter {
    background-color: $li-mdpurple;

    &.alternative {
        .subfooter__brand-logos {
            width: 100%;
        }

        .subfooter__cta {
            background: transparent;
            padding: 20px 15px 0;
        }
    }

    &__brand-logos,
    &__cta {
        text-align: center;
    }

    &__brand-logos {
        padding: 30px 0;

        a, .brand-logo {
            display: inline-block;
            vertical-align: top;
        }

        a {
            min-width: 144px;
        }

        img {
            height: 30px;
        }

        .brand-logo {            
            margin-top: 15px;
        }
    }

    &__cta {
        background: rgba($jh-purple, .47);
        margin: 0 -15px 0;
        padding: 20px 15px;

        a {
            color: $light;
            font-size: 20px;
            font-weight: bold;
            text-decoration: none;
        }

        .secure-logos {
            margin-top: 10px;

            .icon {
                color: $light;
                font-size: 25px;
                margin: 0 8px;
            }
        }
    }
}

@mixin subfooter--min-sm() {
    .subfooter {
        &.alternative {

            .subfooter__cta {
                margin: 0;
                padding: 0;
            }
        }

        &__brand-logos,
        &__cta {
            display: inline-block;
            vertical-align: top;
        }

        &__brand-logos {
            text-align: left;

            a {
                margin-top: 5px;
                min-width: 168px;
            }

            img {
                &.logo {
                    height: 35px;
                }
            }

            .brand-logo {
                margin-top: 5px;
                padding-left: 20px;

                img {
                    max-height: 45px;
                    max-width: none;
                    min-height: 35px;
                }
            }
        }

        &__cta {
            background: none;
            float: right;
            margin: 24px 0 0;
            padding: 0;
            text-align: right;

            .secure-logos {
                margin: 0;
            }
        }
    }
}