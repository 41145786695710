.adv__get-started {
    background-color: $li-purple;
    color: $light;
    padding: 65px 0;
    text-align: center;

    h2 {
        color: inherit;
        font-size: 28px;
        font-weight: 100;
        line-height: 38px;
    }

    p {
        color: inherit;
        margin: 30px 0;
    }

    h3 {
        color: inherit;
        font-weight: 300;

        > a {
            font-weight: 600;
        }
    }

    form {
        margin: 30px auto 0;
        position: relative;
        width: 220px;

        input {
            color: $form-input;
            font-size: em(16);
            font-weight: 600;
            height: 56px;
            margin-bottom: 8px;
            max-width: 200px;
            padding-left: 16px;
            padding-right: 16px;
            width: 100%;
            @include placeholder-label;

            & + label {
                font-size: 1em;
                left: 30px;
                top: 10px;
            }

            &:focus + label,
            &:valid + label,
            &.not-empty + label {
                font-size: 0.8em;
                font-weight: 300;
                left: 10px;
                top: -24px;
            }
        }

        .btn {
            background: $li-blue;
            border: 1px solid $li-blue;
            font-size: em(16);
            font-weight: 600;
            width: 200px;
        }

        input, .btn {
            height: 44px;
        }
        .field-validation-error {
            position: relative;
            top: -5px;
        }
    }

    small {
        display: block;
        font-size: em(14);
        margin-top: 20px;

        a {
            font-weight: bold;
        }
    }
}
@mixin adv__get-started--min-sm() {
  .adv__get-started {
    h2 {
      font-size: em(24);
    }
    form {
      width: 445px;
      input {
        max-width: 212px;
      }
      .btn {
        display: inline-block;
        margin-left: 8px;
        vertical-align: top;
      }
    }
    .field-validation-error {
        padding-left: 12px;
        text-align: left;
    }
  }
}
@mixin adv__get-started--min-md() {
  .adv__get-started {
    h2 {
      font-size: em(34);
      margin-left: -8px;
    }
    p {
        margin-bottom: 0;
    }
  }
}