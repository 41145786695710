.choice__contact {
  background: {
    color: $li-purple;
    position: center;
    repeat: no-repeat;
    size: cover;
  };
  color: $light;
  padding: 50px 15px 37px;
  position: relative;
  text-align: center;

  &:before {
    background-color: rgba($li-purple, 0.1);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  @include breakpoint($sm) {
    padding: 80px 15px 69px;
  }

  h3 {
    color: $light;
    font-size: 34px;
    font-weight: 300;
    line-height: 40px;
    margin-bottom: 30px;

    @include breakpoint($sm) {
      width: 640px;
      margin: {
        left: auto;
        right: auto;
      };
    }
  }

  p {
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;

    @include breakpoint($sm) {
      width: 640px;
      margin: {
        left: auto;
        right: auto;
      };
    }
  }

  .tel {
    background: $li-blue;
    color: $light;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    margin-top: 19px;
    padding: 12px 17px;
    text-decoration: none;

    &:hover, &:active, &:focus {
      outline: none;
      text-decoration: none;
    }

    &::after {
      content: "\e900";
      display: inline-block;
      font-family: li-org;
      font-size: 20px;
      font-weight: 100;
      margin-left: 10px;
      position: relative;
      top: 2px;
    }
  }
}
