.adv__faq {
  padding: 60px 0;
  h2 {
    text-align: center;
  }
  dl {
      margin: 0 auto 22px;
      max-width: 617px;
  }
  dt {
    color: $li-dkpurple;
    padding-bottom: 20px;
    padding-top: 30px;
  }
  dd {
    line-height: 1.6em;
  }
  a {
    color: $li-purple;
    font-weight: 400;
    white-space: nowrap;
  }
}