.request-form-trust {
  color: $li-dkpurple;
  font-size: 16px;
  font-weight: 600;
  padding-top: 6px;
  p {
    margin-left: -10px;
    max-width: 220px;
  }
  strong {
    color: $li-blue;
  }
}
.request-form {
  @include honeypot-field;
  margin: 30px 0;
  .form-group {
    padding: 2px 0;
  }
  .control-label {
    font-size: em(14);
    font-weight: 600;
    text-align: left;
  }
  input {
    @include border-radius(0);
    height: 46px;
    max-width: 290px;
    &.input-short {
      max-width: 140px;
    }
    &.input-med {
      max-width: 160px;
    }
  }
  button {
    font-size: em(14);
    height: 42px;
    margin-top: 10px;
    width: 202px;
  }
  .form-disclaimer {
    font-size: em(10);
    font-weight: 300;
    padding-top: 10px;
    a {
      color: $li-pink;
      font-weight: 400;
    }
  }
  .is--cellphone:hover {
    color: $li-blue;
  }
}
@mixin request-form--min-sm() {
  .request-form {
    margin: 50px 0;
    $label-width: 27%;
    $input-width: 73%;
    .form-group {
      @include clearfix();
    }
    .control-label {
      float: left;
      padding-top: 11px;
      width: $label-width;
      &+div {
        float: left;
        width: $input-width;
      }
    }
    button {
      margin-left: $label-width;
    }
  }
}