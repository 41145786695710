.thank-you {
    text-align: center;

    h1 {
        font-weight: 300;
    }

    p {
        line-height: 24px;
        margin: 0 auto;
        max-width: 480px;
    }

    hr {
        border-top: 1px solid $rule;
        margin: 40px auto;
        max-width: 300px;
    }

    a {
        color: $li-purple;

        text-decoration: none;

        font: {
            size: 28px;
            weight: 600;
        }
    }

    .two-columns {
        padding: 10px 15px 70px;
        text-align: left;

        .main-content {
            margin: 0 auto;
            max-width: 250px;
            padding: 0 0 50px;

            h1 {
                font-size: 24px;
                line-height: 33px;
            }

            p {
                font-size: 12px;
                line-height: 20px;
                margin: 0;
            }

            a {
                color: $li-blue;
                display: inline-block;
                font-size: 18px;
                font-weight: bold;
                line-height: 24px;
                margin-top: 15px;
            }

            hr {
                margin: 20px auto;
                max-width: 100%;
            }
        }


        .brand-card {
            color: $light;
            margin: 0 auto;
            max-width: 320px;
            padding: 40px 30px;

            i {
                display: inline-block;
                font-size: 52px;
                margin-bottom: 30px;
            }

            p {
                font-size: 12px;
                font-weight: 600;
                line-height: 20px;
                margin-bottom: 20px;
            }

            a {
                background: $light;
                border: 0;
                font-size: 14px;
                font-weight: bold;
                height: 39px;
                line-height: 25px;
                margin-top: 10px;
                width: 160px;
            }
        }
    }

    .one-column {
        float: none;
        padding: 10px 15px 70px;
        text-align: center;

        .main-content {
            margin: 0 auto;
            max-width: 250px;
            padding: 0 0 50px;
            h1 {
                font-size: 24px;
                line-height: 33px;
            }

            p {
                font-size: 12px;
                line-height: 20px;
                margin: 0;
            }

            a {
                color: $li-blue;
                display: inline-block;
                font-size: 18px;
                font-weight: bold;
                line-height: 24px;
                margin-top: 15px;
            }

            hr {
                margin: 20px auto;
                max-width: 100%;
            }
        }
    }

    .thank-you-wrap {
        padding: 0 30px 150px;
    }
}

@mixin thank-you--min-sm() {
    .thank-you {
        .two-columns {
            .main-content {
                margin: 0;
                max-width: none;
                padding-right: 20px;
                padding-top: 30px;

                h1 {
                    font-size: 28px;
                    line-height: 38px;
                    margin-bottom: 20px;
                }

                p {
                    font-size: 14px;
                    line-height: 22px;
                }

                a {
                    font-size: 21px;
                    line-height: 28px;
                }
            }

            .brand-card {
                padding: 40px;

                p, a {
                    font-size: 14px;
                }

                p {
                    line-height: 22px;
                    margin-bottom: 30px;
                }
            }
        }

        .one-column {
            .main-content {
                margin: 0 auto;                
                max-width: 469px;
                padding-right: 20px;
                padding-top: 30px;                

                h1 {
                    font-size: 28px;
                    line-height: 38px;
                    margin-bottom: 20px;
                }

                p {
                    font-size: 14px;
                    line-height: 22px;
                }

                a {
                    font-size: 21px;
                    line-height: 28px;
                }
            }
        }
    }
}

@mixin thank-you--min-md() {
    .thank-you {
        .two-columns {
            padding-bottom: 96px;

            .main-content {
                max-width: 440px;
                padding-right: 0;
                padding-top: 30px;

                h1 {
                    font-size: 32px;
                    line-height: 43px;
                }

                p {
                    font-size: 16px;
                    line-height: 24px;
                }

                a {
                    font-size: 24px;
                    line-height: 33px;
                }
            }

            .brand-card {
                float: right;
                max-width: 420px;
                padding: 40px;

                p {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }

        .one-column {
            padding-bottom: 96px;

            .main-content {
                max-width: 500px;
                padding-right: 0;
                padding-top: 30px;

                h1 {
                    font-size: 32px;
                    line-height: 43px;
                }

                p {
                    font-size: 16px;
                    line-height: 24px;
                }

                a {
                    font-size: 24px;
                    line-height: 33px;
                }
            }
        }
    }
}

@mixin thank-you--min-xg() {
    .thank-you {
        .two-columns {
            .brand-card {
                max-width: 470px;
            }
        }
    }
}
