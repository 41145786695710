.adv__avg-cost {
  padding: 50px 0;
  text-align: center;
  &.is--shaded {
      background: $lt-bg;
  }
  h2 {
    font-weight: 300;
    text-align: left;
  }
  h3 {
      color: $text-primary;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.75;
      text-align: left;
  }
  .values {
    margin-top: 30px;
  }
  .value {
    > .amount {
      color: $li-mdpurple;
      font-size: em(34);
      font-weight: 300;
    }
    > .label {
        font-weight: 600;
    }
  }
  .operator {
    color: $li-mdpurple;
    font-size: em(34);
  }
  .total {
    margin-top: 20px;
    &:before {
      background-color: $form-input;
      content: " ";
      display: block;
      height: 1px;
      margin: 0 auto;
      margin-bottom: 20px;
      max-width: 316px;
    }
    > div {
      color: $li-mdpurple;
      font-size: em(34);
      font-weight: 400;
      sup {
          font-size: 50%;
          left: 2px;
          top: -1.4em;
      }
    }
  }
  .disclaimer {
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      margin-top: 40px;
  }
}
@mixin adv__avg-cost--min-sm() {
  .adv__avg-cost {
    .values {
      @include clearfix();
      margin: 40px auto 0;
      max-width: 580px;
    }
    h2 {
        margin-bottom: 20px;
        text-align: center;
    } 
    h3 {
        font-weight: 700;
        line-height: 1.5;
        text-align: center;
    }
    .title {
        margin: 0 auto;
        max-width: 565px;
    }
    .value {
      float: left;
      width: 45%;
      > .label {
        min-height: 40px;
        position: relative;
        > span {
            bottom: 0;
            left: 0;
            position: absolute;
            width: 100%;
        }
      }
      > .amount {
          margin-top: 12px;
      }
    }
    .operator {
      float: left;
      padding-top: 52px;
      width: 10%;
    }
    .total {
      &:before {
        max-width: 600px;
      }
    }    
    .disclaimer {
        font-weight: 600;
        margin: 40px auto 0;
        max-width: 580px;
    }
  } 
}
@mixin adv__avg-cost--min-md() {
    .adv__avg-cost {
        .title {
            max-width: 780px;
        }
        h3 {
            font-size: 18px;
        }
        .total {
            > div {
                font-weight: 300;
            }
        }
        .disclaimer {
            font-size: 12px;
            font-weight: 300;
            max-width: none;
        }
    }
}