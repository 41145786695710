main .nav-col {
  &:after {
    border-right: 1px solid $content-border;
    bottom: -40px;
    content: "";
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
  }
}
.section-nav {
    padding-right: 8%;
    padding-top: 20px;
    position: relative;
    z-index: 20;

    h2 {
        color: $text-primary;
        font-size: em(15);
        font-weight: 600;
        line-height: 1.4em;
        margin-bottom: 23px;
    }

    li {
        line-height: 1em;
        margin-bottom: 23px;
    }

    a {
        color: $li-blue;
        font-size: em(12);
        font-weight: 400;

        &:hover,
        &:focus {
            color: darken($li-blue, 20%);
            text-decoration: none;
        }

        &.active {
            color: $nav-link-primary;
            font-weight: 400;
        }
    }

    &.states-nav {
        h2 {
            padding-right: 30%;
        }

        li {
            margin-bottom: 12px;

            span {
                color: $nav-link-primary;
                font-size: 0.75em;
                font-weight: 400;
            }
        }
    }
}