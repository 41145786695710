.article-list {
    padding-bottom: 60px;

    .article-cta {
        @include clearfix();
        padding-top: 40px;
    }

    .article-image {
        float: left;
        padding-left: 20px;
        text-align: center;
        width: 27.5%;
    }

    img {
        height: auto;
        max-width: 133px;
        width: 100%;
        @include border-radius(50%);
    }

    .article-text {
        float: right;
        font-weight: 300;
        padding-left: 20px;
        padding-right: 5%;
        width: 72.5%;

        .btn-sm {
            background: $li-purple;
            border-color: $li-purple;
            line-height: 2.1;
            padding-top: 0;
        }
    }

    h2 {
        font-size: em(18);
        line-height: 1.4em;
    }
}
@mixin article-list--min-sm() {
  .article-list {
    padding-bottom: 40px;
    position: relative;
    z-index: 20;
    &:after {
      border-right: 1px solid $content-border;
      bottom: 40px;
      content: "";
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 4px;
      top: 40px;
      z-index: 10;
    }
    .article-cta {
      margin-bottom: 10px;
    }
    .article-image {
      width: 33%;
    }
    .article-text {
      padding-left: 30px;
      padding-right: 0;
      width: 67%;
    }
    img {
      max-width: none;
    }
    h2 {
      font-size: em(26);
      font-weight: 300;
      line-height: 1em;
    }
    p {
      font-size: em(14);
    }
  }
}
@mixin article-list--min-lg() {
  .article-list {
    padding-bottom: 50px;
    padding-right: 95px;
    &:after {
      bottom: 0;
      top: 0;
    }
    .article-image {
      padding-left: 0;
      width: 24.25%;
    }
    .article-text {
      width: 75.75%;
    }
    p {
      font-size: em(16);
    }
  }
}
@mixin article-list--min-xg() {
  .article-list {
    .article-image {
      padding-left: 25px;
    }
    img {
      max-width: 135px;
    }
  }
}