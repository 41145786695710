.as-seen-on {
  padding-bottom: 40px;
  padding-top: 30px;
  h2 {
    color: $li-ltgray;
    font-size: em(22);
  }
  li {
    font-size: em(50);
    height: 60px;
    overflow: hidden;
    padding-right: 20px;
    a {
      color: inherit;
    }
    &:last-of-type {
      padding-right: 0;
    }
    i {
      position: relative;
      &.icon-oz {
        font-size: 1.25em;
        top: 2px;
      }
      &.icon-forbes {
        font-size: 2.1em;
        top: -18px;
      }
    }
  }
}
@mixin as-seen-on--min-lg() {
  .as-seen-on {
    padding-top: 60px;
  }
}