.dripform {
  @include honeypot-field;
  margin: 40px auto 150px;
  max-width: 335px;

  .pageWrapContainer {
    overflow: hidden;
    position: relative;

    @include breakpoint($sm) {
      padding-bottom: 40px;
    }
  }

  .pageWrap {
    align-items: flex-start;
    display: flex;
    flex-flow: row;
    height: auto;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    transition: transform 0.4s ease-in-out; /*cubic-bezier(.27,.67,.9,1);*/
  }

  .page {
    display: inline-block;
    flex: 0 0 100%;
    height: 0;
    margin: 0;
    opacity: 0;
    position: relative;
    transition: opacity 0.2s ease-in-out, height .1s ease-in-out;
    vertical-align: top;
    /* page states: .currentPage, .nextPage, .prevPage */
    &.currentPage {
      height: auto;
      opacity: 1;

      &.webkit-dripfix {
        // forces a redraw for an iOS rendering bug when drip form transitions pages. ref: DDS-12728, DDS-12893
        transform: translateZ(0);
      }
    }
  }

  .step-title {
    font-size: 24px;
    line-height: 1.4em;
    margin-bottom: 20px;

    small {
      color: $li-mdpurple;
      display: block;
      font-size: 12px;
      margin-top: 6px;
    }
  }

  .form-group {
    margin-bottom: 20px;
    vertical-align: top;
  }

  label {
    display: block;
    font-size: 14px;
    font-weight: 600;
  }

  input {
    border: 1px solid $li-ltgray;
    height: 42px;
    max-width: 290px;
    padding-left: 12px;
    padding-right: 12px;
    width: 100%;
  }

  button {
    font-size: 14px;
    line-height: 42px;
    max-width: 213px;
    padding: 0 20px;
    position: relative;
    text-align: left;
    width: 100%;
    > span {
      position: absolute;
      right: 5%;
      &:after,
      &:before {
          font-size: 16px;
      }
    }

    &.btn-back {
      border: none;
      box-shadow: none;
      color: $li-blue;
      font-size: 12px;
      max-width: 65px;
      padding: 0 10px;
      &:focus,
      &:active {
        border: none;
        box-shadow: none;
        outline: none;
      }
      > span {
          left: -5%;
          right: auto;
          &:before {
              top: auto;
              vertical-align: middle;
          }
          &:after {
              content: none;
          }
      }
    }
  }

  .field-validation-error {
    display: block;
    max-width: 290px;
    text-align: left;
  }

  .df-disclaimer {
    font-size: 10px;
    font-weight: 300;
    margin: 0 auto;
    max-width: 455px;
    padding-top: 50px;

    a {
      color: $li-pink;
      text-decoration: underline;
    }
  }

  .progress {
    background-color: $border-lt;
    border: 1px solid $form-input;
    border-radius: 20px;
    height: 16px;
    margin: 32px auto 0;
    max-width: 350px;
    width: 100%;

    @include breakpoint($sm) {
      margin-top: 40px;
    }

    @include breakpoint($xg) {
      margin-top: 50px;
    }

    .progress-bar {
      background-color: $progress-bar;
      border-radius: 20px;
      height: 100%;
      width: 0;
    }
  }
}

@mixin dripform--min-sm() {
  .dripform {
    margin-top: 60px;
    max-width: none;
    .step-title {
      font-size: 34px;
      text-align: center;

      small {
        font-size: 14px;
      }
    }

    .fieldset {
      text-align: center;
    }

    .form-group {
      display: inline-block;
      position: relative;
      text-align: left;

      + .form-group {
        margin-left: 30px;
      }
    }

    label {
      font-size: 12px;
      text-align: left;
    }

    input {
      width: 200px;
      &[type='email'] {
        width: 280px;
      }
      &.phone {
        width: 150px;
      }
    }

    button {
      display: inline-block;
      width: 190px;

      &.btn-back {
        margin-left: -69px;
      }
    }

    .form-controls {
      margin-top: 20px;
      text-align: center;
    }

    .field-validation-error {
      position: absolute;
    }

    .df-disclaimer {
      a {
        text-decoration: none;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }
}
