.adv__apply {
  background-image: url("/assets/images/backgrounds/calculator-lg.jpg"); // TODO: Get better images for each breakpoint
  background-position: 50%;
  background-size: cover;
  color: $light;
  min-height: 320px;
  padding: 55px 0;
  position: relative;
  text-align: center;
  .color-overlay {
    background-color: $li-purple;
    height: 100%;
    left: 0;
    opacity: 0.85;
    position: absolute;
    top: 0;
    width: 100%;
  }
  p {
    font-size: em(22);
    font-weight: 400;
    line-height: 1.4em;
    margin-bottom: 26px;
    margin-left: auto;
    margin-right: auto;
    max-width: 807px;
  }
  a {
    font-size: em(16);
    font-weight: 600;
    line-height: 39px;
    line-height: 44px;
    margin-top: 20px;
    padding: 0 20px;
  }
  h2 {
      color: $light;
      font-size: 25px;
      font-weight: 300;
      line-height: 1.36;
      margin: 50px auto 60px;
      padding: 0 5%;
  }
  // variants
  &.is--narrow {
      p {
          max-width: 617px;
      }
  }
}
@mixin adv__apply--min-sm() {
  .adv__apply {
    padding-top: 90px;
    p {
      margin-bottom: 30px;
    }
    h2 {
        font-size: 30px;
        max-width: 617px;
        padding: 0;
    }
  }  
}
@mixin adv__apply--min-md() {
    .adv__apply {
        h2 {
            font-size: 34px;
            max-width: 836px;
        }
    }
}