// Calculate em size from pixels
$browser-context: 16;
@function em($pixels, $context: $browser-context) {
  @return ($pixels / $context) * 1em;
}

// Variables for resolutions
$xs : 320px;
$small: 375px;
$sm : 768px;
$md : 992px;
$lg : 1024px;
$xg : 1200px;
$xxg : 1600px;

// Recomended custom resolutions:
// - iphone 5 : 320px
// - iphone 6 landscape : 375px
// - iphone 6 plus landscape/ nexus 5x, nexus 6P : 412px
// - phone landscape : 568px
// - larger desktop : 1440px/1600 px
// - Mac/iMac : 2560px

// Responsive design breakpoints
@mixin breakpoint($width) {
  @media (min-width: $width){
    @content;
  }
}

//Custom max/min width for breakpoints
@mixin breakpoint-interval($min-width, $max-width) {
  @media screen and (min-width: $min-width) and (max-width: $max-width) {
    @content;
  }
}

// Remove the margin and padding from an element.
@mixin clean {
  margin: 0;
  padding: 0;
}

//Block horizontal centering
@mixin center-block-horizontal() {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

//Block vertical centering
//You must have a parent element with position:relative
@mixin center-block-vertical() {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

//Block horizontal and vertical centering
//You must have a parent element with position:relative
@mixin center-block() {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

//Crossbrowser Opacity
@mixin opacity($opacity) {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$opacity * 100})";
  opacity: $opacity;
}

//Border radius
@mixin border-radius($radius) {
  background-clip: padding-box;  // stops bg color from leaking outside the border
  -webkit-border-radius: $radius;
  border-radius: $radius;
}

// reposition input label/placeholder on focus
@mixin placeholder-label() {
  & + label {
    color: $form-input;
    font-size: 12px;
    font-weight: 600;
    left: 11px;
    pointer-events: none;
    position: absolute;
    top: 11px;
    transition: all 200ms ease-out;
  }
  &:focus + label,
  &:valid + label,
  &.not-empty + label {
    color: inherit;
    left: -2px;
    top: -22px;
  }
}
@mixin placeholder-label-zipcode($size: "") {
    @if $size == "small" {
        & + label {
            color: $form-input;
            font-size: 12px;
            font-weight: 600;
            left: 0;
            pointer-events: none;
            position: absolute;
            right: 0;
            text-align: center;
            top: 13px;
            transition: all 200ms ease-out;
        }

        &:focus + label,
        &:valid + label,
        &.not-empty + label {
            color: inherit;
            font-size: 12px;
            left: -2px;
            right: auto;
            top: -22px;
        }
    }
    @else {
        & + label {
            color: $form-input;
            font-size: 16px;
            font-weight: 600;
            left: 32px;
            pointer-events: none;
            position: absolute;
            text-align: center;
            top: 16px;
            transition: all 200ms ease-out;

            @include breakpoint($md) {
                left: 46px;
            }
        }

        &:focus + label,
        &:valid + label,
        &.not-empty + label {
            color: inherit;
            font-size: 14px;
            left: -2px;
            top: -22px;
        }
    }
}
// add chevron to any button or link by wrapping inner text with <span></span>
@mixin add-chevron($pos: "after") {
  %chevron {
    content: "\e900";
    display: inline-block;
    font-family: li-org;
    font-size: 0.9em;
    position: relative;
    top: 1px;
  }
  @if $pos == "after" {
    &>span:not(.invocaNumber):after {
      @extend %chevron;
      margin-left: 8px;
    }
  }
  @else {
    &>span:after {
      content: none;
      margin-left: 0;
    }
    &>span:not(.invocaNumber):before {
      @extend %chevron;
      margin-right: 8px;
      top: 3px;
      transform: rotate(180deg);
    }   
  }
}

// vertical triangle down. Add triangle to top of any relatively positioned element.
@mixin add-verticalTriangle($width, $height, $color) {
    $w2: $width / 2;

    &::before {
        border-left: $w2 solid transparent;
        border-right: $w2 solid transparent;
        border-top: $height solid $color;
        content: '';
        left: 50%;
        margin-left: -$w2;
        position: absolute;
        top: 0;
    }
}
@mixin update-verticalTriangle($width, $height, $color: "") {
    $w2: $width / 2;

    &::before {
        border-left-width: $w2;
        border-right-width: $w2;

        @if $color == "" {
            border-top-width: $height;
        }
        @else {
            border-top: $height solid $color;
        }
    }
}

@mixin keyframes($animation) {
    @-webkit-keyframes #{$animation} {
        @content;
    }

    @-moz-keyframes #{$animation} {
        @content;
    }

    @-ms-keyframes #{$animation} {
        @content;
    }

    @keyframes #{$animation} {
        @content;
    }
}

@mixin honeypot-field {
    #winnie,
    label[for='winnie'] {
        display: none;
    }
}